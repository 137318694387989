@media (max-width: 767px)
    .header-font
        font-size: 16px
        bottom: 0px;
        margin-left: 25px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    .fx-ai-con
        display:flex
        align-items: center
        justify-content: flex-end
    .fx-ai-title
        display:flex
        align-items: center
    .nav-menu-btn
        padding: 6px;
        background: #000;
        margin-right: 10px;
        font-size: 17px;
        position: fixed;
        left: 8px;
        border-radius: 4px;

@media (min-width: 768px)
    .header-font
        font-size: 28px
        bottom: 0px;
        margin-left: 25px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    .nav-menu-btn
        padding: 6px;
        background: #000;
        margin-right: 10px;
        font-size: 17px;
        position: fixed;
        top: 23px;
        left: 8px;
        border-radius: 4px;
.header-container
    padding 18px 5px 18px 5px
    margin 0
    position fixed
    z-index: 1011 !important
    width: 100%
    border-bottom: 2px solid #ddd

.hide-header
    z-index: 0

.inline-display
    display inline-block    

.relative-position
    position relative
    
.header-setting-button
    font-size: 20px 
    background-color ncss-white

.header-brand
    left 3rem  
    top 0rem 

.header-brand-padding
    padding-left: 20px !important

.header img 
  float: left;
  width: 100px;
  height: 100px;
  background: #555;

.logo
    width: 200px;
    height: 40px;
.header-name-padding
    padding 7px  

.menu-icons
    width 7%
    
.top-align
    float: right;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 48px; 
    font-size: 15px;
    padding-top: 15px;
    @media screen and (max-width 1024px)
        padding-right: 2rem; 

.header-name
    float right

.header-user-name
    display: inline;
    line-height: 15px;
    padding: 0px 15px;
    @media screen and (max-width 600px)
        display: none; 
        
.bg-header
  background-color: black

.header-menu-btn
    float right

.hidden-text
   color: black !important;
   font-size: 12px !important
   text-align: center;
   letter-spacing: 1px;
   margin-top: 10px;
   padding-left: 7px;

.nav-menu-btn:hover
    border : 1px solid  #fff

.navbar-close-btn
    position: absolute;
    right: 3px;
    top: 18px;

    font-size: 18px

.brand-font-size
    font-size: 85%


#cartCount 
    font-size: 12px;
    background: #ff4500;
    color: #fff;
    padding: 0 8px;
    margin-right: -17px;
    float: right;
    margin-right: -21px;

.badge-cart
  border-radius: 12px;