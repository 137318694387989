.dashboard-search1
    input
        width: 65%;
        font-weight: 400;
        font-size: 16px;
        //margin: auto;
        display: block;
        //padding-left: 40px;
        //padding-right: 40px;
        background: #fff;
        -webkit-box-shadow: 0px 8px 20px #00000059;
        box-shadow: 0px 8px 20px #00000059;
        height: 39px;

.dashboard-search
    input
        font-weight: 400;
        font-size: 16px;
        display: block;
        background: #f5f5f5 !important;
        //box-shadow: 0px 8px 20px #00000070;
        height: 48px;
        width: 65%;
        padding: 18px;
        border: 2px solid #ddd;
        border-radius: 40px 0px 0px 40px;



.dashboard-search div
    z-index: 10 !important
    //float: right
    cursor: pointer;
    font-weight: bold
    background: #fff !important
    //top: 28% !important
    max-height: 300px !important
    //border-radius: 3px !important
    //color:#0078b9 !important
    

.dashboard-search div::-webkit-scrollbar 
    background-image: linear-gradient(to right,white 50%, grey 50%,white 51%);
    width: 10px
    

.dashboard-search div::-webkit-scrollbar-thumb 
    background-color: grey;
    border-radius: 10px;

.airbagpage-search
    input
        font-weight: 400;
        font-size: 16px;
        display: block;
        height: 50px;
        width: 100%
        border: 2px solid #ddd;
        border-radius: 40px;
        background: #f5f5f5 !important;
        padding: 18px;
        border: 2px solid #ddd;
        border-radius: 40px 0px 0px 40px;

.airbagpage-search div
    box-sizing: border-box !important
    z-index: 10 !important
    cursor: pointer;
    font-weight: bold
    background: #fff !important
    max-height: 300px !important
    

.airbagpage-search div::-webkit-scrollbar 
    background-image: linear-gradient(to right,white 50%, grey 50%,white 51%);
    width: 10px
    

.airbagpage-search div::-webkit-scrollbar-thumb 
    background-color: grey;
    border-radius: 10px;


.universal-search-maindiv
    overflow: visible !important
    box-sizing: border-box !important


.universal-search
    input
        //box-shadow: inset 0 1px 0 0 #999, inset -1px 0 0 0 #222, inset 0 -1px 0 0 #222, inset 1px 0 0 0 #999;
        min-height: 40px;
        width:100%
        overflow: visible !important
        font-color: #000
        border: 2px solid #ddd;
        border-radius: 40px;
        background: #f5f5f5 !important;
        padding: 15px;
        border: 2px solid #ddd;
        border-radius: 40px 0px 0px 40px;



.universal-search div
    z-index: 11 !important
    cursor: pointer;
    font-weight: bold
    background: #fff !important
    max-height: 198px !important

.bg-dropdown
    background-color: #eee;

.bg-dropdown-color
    background-color: transparent;

.display-none 
    display: none !important

.universal-search div::-webkit-scrollbar 
    background-image: linear-gradient(to right,white 50%, grey 50%,white 51%);
    width: 10px
    

.universal-search div::-webkit-scrollbar-thumb 
    background-color: grey;
    border-radius: 10px;

.serch-dropdown 
    display: inline-block

.img-wrap
    float: left;
    //padding: 0 10px;
    //width: 30px;
    //height: 30px;

.search-name-desc
    float: left;
    width: 74% !important;
    margin-left: 8px;
    margin-right: 10px;
    margin-bottom: 17px;
    text-align: justify;

.search-name 
    font-size: 15px;
    text-decoration: none;
    font-weight: bold;
    color: #000;
    word-wrap: break-word;

.search-desc 
    color: #8a8a8a;
    font-size: 14px;
    font-weight: 600;

.search-clear-left 
    clear: left;

.render-item
    display: flex
    align-items: left
    margin: 5px

.render-imgStyle
    vertical-align: middle
    margin: 10
    display: flex
    justify-content: space-between
    //max-width: 100px

.loaditem
  font-size: 13px
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center
  cursor: default;
  color: #8a8a8a;

.noitem
  text-align: center;
  cursor: default;
  font-size: 13px
  padding-top: 10px;
  padding-bottom: 10px;

.search-pop
    width: 50%;
    top:40%
    max-height: 40vh

@media (min-width: 768px) and (max-width: 1024px)
  .search-pop 
    width:82%;
    top: 40%;
    max-height: 40vh;

@media (max-width: 767px)  
 .search-pop 
    width:92%;
    top: 40%;
    max-height: 56vh;  
 .renderItem-imgStyle
    display:none   
    




