.chip 
    display: inline-block;
    padding: 0 15px;
    height: 30px;
    font-size: 14px;
    font-weight: 900;
    line-height: 30px;
    color: rgba(0,0,0,.8);
    background-color: hsl(0,0%,90%);
    margin-right: 4px;
    margin-bottom: 4px;


.closebtn 
  padding-left: 10px;
  color: rgba(0,0,0,.8);
  font-weight: bold;
  float: right;
  font-size: 20px;
  cursor: pointer;


.closebtn:hover 
  color: #D3DAE1;
