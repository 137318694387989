.size-quantity-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-left:14px;
}
.size-quantity-table-row-header{
   border: 2px solid rgba(0,0,0,0.3);
  }
.size-quantity-table-header {
  text-align: left;
  padding: 2px;
  text-align: center
  background: rgba(0,0,0,0.8)
  color #fff
  font-weight 600
}
.size-quantity-table-row
  border: 1px solid #676363;
  text-align: center
  padding: 4px;
  font-weight 600

.size-quantity-table-row:nth-child(even) {
  //background-color: #676363;
}