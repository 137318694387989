@require 'variables'

.donut

 .title
   font-weight: 900;

 .legend-label
   font-weight 700
   font-size 20px
   fill #8D8D8D

 .legend-label-lg
   font-weight: 700;
   font-size: 29px;

 .center-text
   font-weight: 800;
   font-size: 21px;