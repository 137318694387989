.form-Container

    margin: auto;
    overflow-y: scroll;
    height: 44vh;
.float-right
        float: right
.text 
  text-align: center;
  padding: 15px;
  margin: 20px;
  @media (max-width: 1024px)
    margin: 0px
.text-color-red
  color: red
.button-align-right
  text-align:right; 
  width:100%; 
  padding:0;
  
.decoration-price-textbox
  min-height 25px !important
  margin: 4px

.link-text
  color: #0758A7
  cursor: pointer
  text-decoration: underline
  font-weight: 600

.new_item_textbox
  min-height: 32px !important;
  margin: 4px

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color:  #111;//#2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px  #111;;//#2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
Button:disabled{
  background: grey;
}
.addProductModal
    height: 70vh;
    overflow-y: hidden;
    width: 75%
    background: #e8e8e8
    @media screen and (max-width: 767px)
      height: 60vh

.decorationModal{
  height 70vh;
    overflow-y: auto;
    width: 75%
    background: #e8e8e8
}
/* width */
.decorationModal::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.decorationModal::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin:5px;
}

/* Handle */
.decorationModal::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.decorationModal::-webkit-scrollbar-thumb:hover {
  background: #555;
}
  .season-add-new
    padding: 4px
.bg-addproduct
  background: rgba(152,152,152,.2)

.upload-button{
    border: 1px solid #ccc;
    font-size: 12px;
}

.multiple-image-options
  border 1px solid black
  margin-top: -21px
  margin-left 8px
  margin-right 8px
  width 98.1%
  
  @media screen and (max-width 1680px)
      .form-Container
        height 51vh
  @media screen and (max-width 1366px)
      .form-Container
        height 44vh
  @media screen and (max-width 1280px)
      .form-Container
        height 44vh
  @media screen and (max-width 1024px)
      .form-Container
        height 40vh

.height-dropdown
    height: 42px !important
    min-height: 42px !important
    font-weight: bold
    
.select__control
    height: 42px !important
    min-height: 42px !important 

  .hide{
    display: none !important;
  }  
  .show{
    display: block !important;
  }

.mass-popup-table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

.mass-table-body {
  overflow-y:auto;
  height: 150px;
}

.mass-popup-th {
   width: 50%;
   position: sticky;
   top: 0;
   background-color: #a0a0a0;
}

.mass-popup-td {
  text-align: left;
  font-size: 14px
  padding:8px;
}

tr:nth-child(even) {
  background-color: #f1f1f1;
}

.popup-size {
  font-size: 20px
}

.mt-15{
  margin-top: 25px
}

.mb-15{
  margin-bottom: 15px
}

