.footer-container {
  background-color: #111;
  color: #fff;
  padding: 0.7rem 0;
}
.footer-container a {
  color: #fff;
}
.footer-copyright {
  display: block;
  float: right;
}
.footer-margin-adjust {
  margin-top: 0px;
  margin-bottom: 0px;
}
.vertical-line {
  border-left: 3px solid #fff;
  margin: 15px;
}
.footer-hr {
  border-color: #8d8d8d;
}
.page-align-items {
  flex: 1 0 auto;
  min-height: 60vh;
  margin: 0 30px 0 30px;
}
@media screen and (max-width: 990px) {
  .page-align-items {
    margin: 44px 20px 60px 20px;
  }
}
@media screen and (max-width: 640px) {
  .page-align-items {
    margin: 44px 0px 0px 0px;
  }
}
.page-align-fixed-items {
  overflow: hidden;
  padding: 0px 0px 0px 0px !important;
}
html {
  background-color: #f1f1f1;
}
body {
  background-color: #f1f1f1;
}
.link {
  text-decoration: underline;
}
.u-va-tb {
  vertical-align: text-bottom;
}
.hidden {
  display: none;
}
.invisible {
  opacity: 0;
}
.center {
  margin-left: auto;
  margin-right: auto;
}
.relative-container {
  position: relative;
}
ul.list-unstyled {
  list-style-type: none;
}
ul.horizontal li {
  display: inline-block;
  list-style-type: none;
}
.page-header h1.page-title-has-back {
  position: relative;
}
.page-header h1.page-title-has-back .page-title-back {
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  font-size: 0.8rem;
  color: #3498db;
}
.page-header .ncss-flyout-btn {
  padding: 2px 40px 2px 20px;
}
.page-sub-header h1,
.page-sub-header h2 {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}
.section-header .section-header-item {
  display: inline-block;
  vertical-align: middle;
}
.u-rotate-right {
  transform: rotate(90deg);
}
.u-rotate-left {
  transform: rotate(270deg);
}
.ncss-dl dt {
  font-weight: 600;
}
.ncss-dl dd {
  padding: 0 0 0.5rem 0;
}
.ncss-dl-inline dt,
.ncss-dl-inline dd {
  display: inline;
  margin: 0;
}
.ncss-dl-inline dt {
  font-weight: 600;
}
.ncss-dl-inline dd {
  padding: 0 0 0.5rem 0.5rem;
}
.ncss-dl-inline dd:after {
  content: '\A';
  white-space: pre;
}
.selectable-highlight {
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid #48bd1f;
  display: inline-block;
  font-weight: bold;
  color: #8d8d8d;
  overflow: auto;
}
.u-nowrap {
  white-space: nowrap;
}
.flex-container {
  display: flex;
  align-items: flex-end;
}
.flex-container.start {
  align-items: start;
}
.flex-container.center {
  align-items: center;
  justify-content: center;
}
.flex-fill {
  flex: 1 0 auto;
}
.flex-fluid {
  flex: 1 1 auto;
}
.flex-static {
  flex: 0 0 auto;
}
.u-half-width {
  width: 50%;
}
@media only screen and (min-width: 640px) {
  .u-half-width-md {
    width: 50%;
  }
}
@media only screen and (min-width: 1024px) {
  .u-half-width-lg {
    width: 50%;
  }
}
@media only screen and (min-width: 1280px) {
  .u-half-width-xl {
    width: 50%;
  }
}
@media only screen and (min-width: 1440px) {
  .u-half-width-xl .u-half-width-xxl {
    width: 50%;
  }
}
.card-container {
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
}
.circle {
  border-radius: 50%;
  padding: 1px 3px;
}
.page-header,
h1.page-title-has-back {
  position: relative;
  margin: 1rem 0;
}
.rounded {
  border-radius: 25%;
}
.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.left-float {
  float: left;
}
.right-float {
  float: right;
}
.middle-float {
  float: middle;
}
.grey-button {
  background-color: rgba(0,0,0,0.8);
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  padding: 1px 9px;
}
.grey-button-icon {
  background-color: rgba(0,0,0,0.8);
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  padding: 1px 9px;
  border-radius: 15%;
}
.big-button {
  font-size: 30px;
  background-color: #fff;
  border: 1px solid #8d8d8d;
}
.big-button:focus {
  outline: 0;
}
.big-button-selected {
  font-size: 30px;
  background-color: #3498db;
  border: 1px solid #3498db;
}
.big-button-selected:focus {
  outline: 0;
}
.icon-button-margin {
  margin: 5px !important;
}
.button-top-adjust {
  position: relative;
  top: 1.3rem;
}
.sub-heading {
  font-size: 25px;
}
.sub-header {
  font-size: 16px;
  color: #8d8d8d;
}
.pointer-cursor {
  cursor: pointer;
}
.extra-margin {
  margin-bottom: 125px;
}
.nav-header-wrapper {
  width: 100%;
  background: #111;
  color: #fff;
  height: 68px;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
}
.nav-header-container {
  display: flex;
  width: 100%;
}
.nav-header-container a {
  color: #fff;
}
.nav-header-title {
  flex: 1 1 auto;
  padding-top: 8px;
}
.nav-header-title span {
  font-size: 2rem;
}
@media screen and (min-width: 641px) {
  .nav-header-title span {
    margin-left: 70px;
  }
}
.nav-header-title i {
  position: absolute;
  font-size: 22px;
  margin-top: 12px;
}
.nav-header-block {
  flex: 0 0 auto;
}
.nav-header-block:last-child {
  margin-right: 0;
}
.nav-header-block .nav-header-menu li {
  height: 68px;
  margin-right: 1rem;
  display: inline-block;
}
.nav-header-block .nav-header-menu li a,
.nav-header-block .nav-header-menu li span {
  padding: 0 0.5rem;
  font-size: 1.3rem;
  font-weight: 700;
  vertical-align: middle;
  line-height: 68px;
  display: inline-block;
  height: 68px;
}
.nav-header-block .nav-header-menu li a.active,
.nav-header-block .nav-header-menu li span.active {
  border-bottom: 3px solid #3498db;
  color: #3498db;
}
.dropdown-pcx {
  width: 100%;
}
.Select {
  position: relative;
}
.Select,
.Select div,
.Select input,
.Select span {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.Select.is-disabled > .Select-control {
  background-color: #f9f9f9;
}
.Select.is-disabled > .Select-control:hover {
  box-shadow: none;
}
.Select.is-disabled .Select-arrow-zone {
  cursor: default;
  pointer-events: none;
}
.Select-control {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 8px 16px 8px 8px;
}
.Select-control:hover {
  box-shadow: 0 1px 0 rgba(0,0,0,0.06);
}
.is-searchable.is-open > .Select-control {
  cursor: text;
}
.is-open > .Select-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: #fff;
  border-color: #b3b3b3 #ccc #d9d9d9;
}
.is-open > .Select-control > .Select-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}
.is-searchable.is-focused:not(.is-open) > .Select-control {
  cursor: text;
}
.is-focused:not(.is-open) > .Select-control {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 0 3px rgba(0,126,255,0.1);
}
.Select-placeholder {
  padding-left: 10px;
}
.Select-placeholder,
.Select--single > .Select-control .Select-value {
  bottom: 0;
  color: #aaa;
  left: 8px;
  line-height: 34px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;
  vertical-align: middle;
  padding-top: 4px;
}
.Select.Select--multi.is-open .Select-placeholder {
  display: none;
}
.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #333;
}
.has-value.Select--single > .Select-control .Select-value a.Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label {
  cursor: pointer;
  text-decoration: none;
}
.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  color: #007eff;
  outline: none;
  text-decoration: underline;
}
.Select__menu {
  display: inline-block;
  width: 100%;
  height: auto;
  position: relative !important;
  z-index: 9 !important;
}
.Select__menu-list {
  position: relative !important;
  height: 200px;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}
.Select__control {
  margin-top: 0 !important;
  min-height: 42px !important;
}
.Select-input {
  height: 3rem;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: top;
  border-color: #ccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}
.select-input-font {
  -webkit-font-smoothing: antialiased;
  color: #253858;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
}
.dropdown-pcx:after {
  color: #0ebeff;
  right: 1px;
  top: 1px;
  padding: 15px 0px 0px 8px;
  position: absolute;
  pointer-events: none;
}
.select::-ms-expand {
  display: none;
}
.Select-input > input {
  width: 100%;
  background: none transparent;
  border: 0 none;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  line-height: 14px;
/* For IE 8 compatibility */
  padding: 0;
/* For IE 8 compatibility */
  -webkit-appearance: none;
}
.is-focused .Select-input > input {
  cursor: text;
}
.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}
.Select-control:not(.is-searchable) > .Select-input {
  outline: none;
}
.Select-loading-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 16px;
}
.Select-loading {
  -webkit-animation: Select-animation-spin 400ms infinite linear;
  -o-animation: Select-animation-spin 400ms infinite linear;
  animation: Select-animation-spin 400ms infinite linear;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-right-color: #333;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.Select-clear-zone {
  -webkit-animation: Select-animation-fadeIn 200ms;
  -o-animation: Select-animation-fadeIn 200ms;
  animation: Select-animation-fadeIn 200ms;
  color: #999;
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 17px;
}
.Select-clear-zone:hover {
  color: #d0021b;
}
.Select-clear {
  display: inline-block;
  font-size: 18px;
  line-height: 1;
}
.Select--multi .Select-clear-zone {
  width: 17px;
  height: 23px;
  vertical-align: top;
}
.Select-arrow-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  padding-right: 5px;
}
.Select-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 0;
  width: 0;
}
.is-open .Select-arrow,
.Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #666;
}
.Select--multi .Select-multi-value-wrapper {
  display: inline-block;
}
.Select .Select-aria-only {
  display: inline-block;
  height: 1px;
  width: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}
.group-badge-styles {
  background-color: #ebecf0;
  border-radius: 2em;
  color: #172b4d;
  display: inline-block;
  font-size: 12;
  font-weight: normal;
  line-height: 1;
  min-width: 1;
  padding: 0.16666666666667em 0.5em;
  text-align: center;
}
.Select__group-heading,
.Select__group {
  padding: 0 !important;
}
.group-styles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0,0,0,0.8);
  color: #fff;
  padding-left: 3px;
  font-size: 14px;
}
@-webkit-keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.Select-menu-outer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0,0,0,0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 180px;
  position: relative;
  top: 95%;
  width: 100%;
  z-index: 100;
  -webkit-overflow-scrolling: touch;
}
.Select-menu-outer {
  z-index: 999 !important;
}
.Select-menu {
  max-height: 198px;
  overflow-y: auto;
}
.Select-option {
  box-sizing: border-box;
  background-color: #fff;
  color: #666;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}
.Select-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.Select-option.is-selected {
  background-color: #f5faff;
/* Fallback color for IE 8 */
  background-color: rgba(0,126,255,0.04);
  color: #333;
}
.Select-option.is-focused {
  background-color: #ebf5ff;
/* Fallback color for IE 8 */
  background-color: rgba(0,126,255,0.08);
  color: #333;
}
.Select-option.is-disabled {
  color: #ccc;
  cursor: default;
}
.Select-noresults {
  box-sizing: border-box;
  color: #999;
  cursor: default;
  display: block;
  padding: 8px 10px;
}
.Select--multi .Select-input {
  vertical-align: top;
  margin-left: 10px;
  padding-left: 0;
  padding-right: 0;
}
.Select--multi.has-value .Select-input {
  margin-left: 5px;
  height: 23px;
  vertical-align: top;
}
.Select--multi .Select-value {
  background-color: #ebf5ff;
/* Fallback color for IE 8 */
  background-color: rgba(0,126,255,0.08);
  border-radius: 2px;
  border: 1px solid #c2e0ff;
/* Fallback color for IE 8 */
  border: 1px solid rgba(0,126,255,0.24);
  color: #007eff;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1.4;
  margin-left: 5px;
  vertical-align: top;
}
.css-2613qy-menu {
  display: inline-block !important;
  background-color: #000;
}
.Select--multi .Select-value-icon,
.Select--multi .Select-value-label {
  display: inline-block;
  vertical-align: middle;
}
.Select--multi .Select-value-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  cursor: default;
  padding: 2px 5px;
}
.Select--multi a.Select-value-label {
  color: #007eff;
  cursor: pointer;
  text-decoration: none;
}
.Select--multi a.Select-value-label:hover {
  text-decoration: underline;
}
.Select--multi .Select-value-icon {
  cursor: pointer;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-right: 1px solid #c2e0ff;
/* Fallback color for IE 8 */
  border-right: 1px solid rgba(0,126,255,0.24);
  padding: 1px 5px 3px;
}
.Select--multi .Select-value-icon:hover,
.Select--multi .Select-value-icon:focus {
  background-color: #d8eafd;
/* Fallback color for IE 8 */
  background-color: rgba(0,113,230,0.08);
  color: #0071e6;
}
.Select--multi .Select-value-icon:active {
  background-color: #c2e0ff;
/* Fallback color for IE 8 */
  background-color: rgba(0,126,255,0.24);
}
.Select--multi.is-disabled .Select-value {
  background-color: #fcfcfc;
  border: 1px solid #e3e3e3;
  color: #333;
}
.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid #e3e3e3;
}
.Select--multi.is-disabled .Select-value-icon:hover,
.Select--multi.is-disabled .Select-value-icon:focus,
.Select--multi.is-disabled .Select-value-icon:active {
  background-color: #fcfcfc;
}
.Select-value {
  border: 1px solid #a9a9a9 !important;
  background-color: #000;
}
.Select-value-icon {
  display: none !important;
}
.Select-value-label {
  background-color: #fff;
  color: #111;
}
@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn);
  }
}
@-moz-keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
@-webkit-keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
@-o-keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
.ncss-container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
[class*=g72] {
  position: static;
  font-size: 100%;
}
.ncss-radio-container {
  position: relative;
  text-align: left;
}
.border-warn {
  box-shadow: inset 0 0 0 1px #eeb450;
}
.text-color-accent {
  color: #3498db;
}
.text-color-warn {
  color: #eeb450;
}
.bg-warn {
  background-color: #eeb450;
}
.ncss-select-container .ncss-error-msg,
.ncss-checkbox-container .ncss-error-msg {
  display: none;
  color: #fe0000;
  font-size: 12px;
  line-height: 2;
}
.ncss-select-container.error .ncss-error-msg,
.ncss-checkbox-container .ncss-error-msg {
  display: block;
}
.ncss-input-container .ncss-input,
.ncss-input-container .ncss-textarea,
.ncss-textarea-container .ncss-input,
.ncss-textarea-container .ncss-textarea {
  z-index: inherit;
}
.h1,
h1 {
  font-size: 30px;
  line-height: 1.6;
}
.lh-h1 {
  line-height: 0.933333333333333;
}
.h2,
h2 {
  font-size: 19px;
  line-height: 1.263157894736842;
}
.lh-h2 {
  line-height: 0.894736842105263;
}
.h3,
h3 {
  font-size: 20px;
  line-height: 1.2;
}
.lh-h3 {
  line-height: 0.9;
}
.h4,
h4 {
  font-size: 19px;
  line-height: 1.263157894736842;
}
.lh-h4 {
  line-height: 0.894736842105263;
}
.h5,
h5 {
  font-size: 14px;
  line-height: 1.714285714285714;
}
.lh-h5 {
  line-height: 0.857142857142857;
}
.h6,
h6 {
  font-size: 12px;
  line-height: 2;
}
.lh-h6 {
  line-height: 0.833333333333333;
}
@media only screen and (min-width: 641px) {
  .h1,
  h1 {
    font-size: 40px;
    line-height: 1.2;
  }
  .lh-h1 {
    line-height: 0.95;
  }
  .h2,
  h2 {
    font-size: 28px;
    line-height: 1.714285714285714;
  }
  .lh-h2 {
    line-height: 0.928571428571429;
  }
  .h3,
  h3 {
    font-size: 24px;
    line-height: 1;
  }
  .lh-h3 {
    line-height: 0.916666666666667;
  }
  .h4,
  h4 {
    font-size: 22px;
    line-height: 1.090909090909091;
  }
  .lh-h4 {
    line-height: 0.909090909090909;
  }
  .h5,
  h5 {
    font-size: 16px;
    line-height: 1.5;
  }
  .lh-h5 {
    line-height: 0.875;
  }
  .h6,
  h6 {
    font-size: 14px;
    line-height: 1.714285714285714;
  }
  .lh-h6 {
    line-height: 0.857142857142857;
  }
}
@media only screen and (min-width: 1025px) {
  .h1,
  h1 {
    font-size: 40px;
    line-height: 1.2;
  }
  .lh-h1 {
    line-height: 0.95;
  }
  .h2,
  h2 {
    font-size: 34px;
    line-height: 1.411764705882353;
  }
  .lh-h2 {
    line-height: 0.941176470588235;
  }
  .h3,
  h3 {
    font-size: 28px;
    line-height: 1.714285714285714;
  }
  .lh-h3 {
    line-height: 0.928571428571429;
  }
  .h4,
  h4 {
    font-size: 16px;
    line-height: 1.5;
  }
  .lh-h4 {
    line-height: 0.875;
  }
  .h5,
  h5 {
    font-size: 16px;
    line-height: 1.5;
  }
  .lh-h5 {
    line-height: 0.875;
  }
  .h6,
  h6 {
    font-size: 14px;
    line-height: 1.714285714285714;
  }
  .lh-h6 {
    line-height: 0.857142857142857;
  }
}
.ncss-flyout-btn,
.ncss-flyout-options .ncss-option {
  display: inline-block;
  font-size: 14px;
  line-height: 1.714285714285714;
  text-decoration: none;
  cursor: pointer;
}
.ncss-flyout {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.ncss-flyout:not([disabled]):not(.disabled):hover .ncss-flyout-options {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.ncss-flyout.disabled,
.ncss-flyout:disabled {
  opacity: 0.5;
}
.ncss-flyout.disabled .ncss-flyout-btn,
.ncss-flyout:disabled .ncss-flyout-btn {
  cursor: default;
}
.ncss-flyout-btn {
  position: relative;
  z-index: 1;
  padding: 8px 40px 8px 20px;
}
.ncss-flyout-btn:after {
  font-family: nike-glyphs, sans-serif;
  content: '\E007';
  font-size: 10px;
  position: absolute;
  top: 50%;
  right: 16px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.ncss-flyout-span,
.ncss-flyout-span-num {
  margin-left: 4px;
  color: #707070;
  vertical-align: top;
}
.ncss-flyout-span-num:before {
  content: "(";
}
.ncss-flyout-span-num:after {
  content: ")";
}
.ncss-flyout-options {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  right: 0;
  background: #f1f1f1;
  max-height: 272px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-shadow: inset -1px 0 0 0 #e5e5e5, inset 0 -1px 0 0 #e5e5e5, inset 1px 0 0 0 #e5e5e5;
  box-shadow: inset -1px 0 0 0 #e5e5e5, inset 0 -1px 0 0 #e5e5e5, inset 1px 0 0 0 #e5e5e5;
  -webkit-transform: translate(0, -20%);
  -ms-transform: translate(0, -20%);
  transform: translate(0, -20%);
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.ncss-flyout-options .ncss-option {
  width: 100%;
  color: #111;
  background: 0 0;
  -webkit-box-shadow: inset 0 1px 0 0 #e5e5e5;
  box-shadow: inset 0 1px 0 0 #e5e5e5;
}
.ncss-flyout-options .ncss-option:first-child {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ncss-flyout-options .ncss-option:hover {
  background: #dfdfdf;
  color: #111;
}
.ncss-flyout-options .ncss-option.active,
.ncss-flyout-options .ncss-option:active {
  background: #111;
  color: #fff;
  text-decoration: none;
  -webkit-box-shadow: inset 0 1px 0 0 #111;
  box-shadow: inset 0 1px 0 0 #111;
}
.typeahead-dropdown-container {
  overflow: visible;
}
.typeahead-dropdown-container .typeahead-input {
  position: relative;
}
.typeahead-dropdown-container.focus .typeahead-options {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}
.ncss-flyout-btn {
  border: 1px solid #ddd;
  display: block;
}
.ncss-select-container {
  width: 100%;
  position: relative;
  display: block;
  text-align: left;
  overflow: visible;
}
.ncss-select-container.error .Select-control,
.ncss-select-container.error .is-focused:not(.is-open) > .Select-control {
  border-color: #fe0000;
}
.ncss-select-container .Select-value {
  display: inline-block;
  margin-left: 10px;
}
.no-border-custom {
  border: none;
}
.ncss-form-group .ncss-checkbox-container .ncss-label {
  font-weight: normal;
}
.ncss-form-group {
  margin-top: 1rem;
}
.ncss-form-group .ncss-label {
  font-weight: bold;
}
@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.spinner:before {
  content: 'Loading…';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 1rem;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
}
.spinner.spinner-lg:before {
  width: 10rem;
  height: 10rem;
  margin-top: -5rem;
  margin-left: -5rem;
}
.spinner:not(:required):before {
  content: '';
  border-radius: 50%;
  border-top: 4px solid rgba(0,0,0,0.6);
  border-right: 2px solid transparent;
  animation: spinner 0.6s linear infinite;
  -webkit-animation: spinner 0.6s linear infinite;
}
.modal-overlay-spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.2);
  z-index: 13;
}
.spinner-lg-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 24px;
  font-weight: bold;
  color: rgba(0,0,0,0.6);
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
@-moz-keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.markdown-container ul {
  list-style: disc inside;
}
.markdown-container ol {
  list-style: decimal inside;
}
.markdown-container ul ul,
.markdown-container ol ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 15px;
}
.markdown-container ol ol,
.markdown-container ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 15px;
}
.markdown-container a {
  color: #3498db;
  text-decoration: underline;
}
.markdown-container h1,
.markdown-container h2,
.markdown-container h3,
.markdown-container h4,
.markdown-container h5 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.markdown-container h4 {
  font-size: 22px;
}
.markdown-container h5 {
  font-size: 18px;
}
.markdown-container img {
  margin: 0.5rem 0;
}
.markdown-container code {
  background-color: #f7f7f7;
  color: #d04670;
  padding: 2px 4px;
}
.markdown-container pre code {
  background-color: #f7f7f7;
  display: inline-block;
}
.tooltip-markdown a {
  color: #fff;
  text-decoration: underline;
}
.tooltip-markdown .markdown-container code {
  background-color: #888;
  color: #fff;
  font-family: Consolas, monaco, monospace;
}
.menu-container {
  display: flex;
  width: 100%;
  height: 100%;
}
.menu-lightbox {
  opacity: 0;
  visibility: hidden;
  background: rgba(0,0,0,0);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1012;
  transition: opacity 300ms ease;
}
.show-menu .menu-lightbox {
  opacity: 1;
  visibility: visible;
  background: rgba(0,0,0,0.4);
}
.menu-logo {
  font-size: 22px;
  vertical-align: baseline;
}
.menu-aside {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  width: 300px;
  height: 100%;
  z-index: 10001;
  overflow: hidden;
  transform: translateX(-100%);
  transition: transform 300ms ease;
  background: #fff;
}
.menu-aside .nav {
  width: 100%;
  flex: 0 0 auto;
  overflow-y: auto;
  background: #fff;
}
.menu-aside .footer {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menu-aside .footer .footer-spacer {
  display: block;
  flex: 1 1 0;
}
.menu-aside .footer span {
  margin: 0 auto;
  flex: 0 0 auto;
  display: flex;
}
.show-menu .menu-aside {
  transform: translateX(0);
  border-right: 2px solid #ddd;
  box-shadow: inset 0 1px 0 0 #e5e5e5, inset -1px 0 0 0 #e5e5e5, inset 0 -1px 0 0 #e5e5e5, inset 1px 0 0 0 #e5e5e5;
}
.viewport {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.menu-btn {
  position: fixed;
  top: 8px;
  left: 8px;
}
.profile-nav {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profile-nav .profile-nav-item {
  flex: 0 0 auto;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}
.profile-nav .profile-nav-item span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}
.profile-nav.profile-nav-item {
  flex: 1 1 auto;
}
.profile-nav .user-info {
  flex: 1 1 auto;
}
.badge-notification {
  position: absolute;
  right: -8px;
  top: -8px;
  font-size: 1rem;
  background-color: #f00;
  color: #fff;
  display: inline;
  line-height: 0.8rem;
  padding: 3px 5px;
}
.menu-header {
  text-align: left;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: #000 !important;
}
.seperation-line {
  color: #e5e5e5;
  margin: 15px 0px 30px 0px;
  border: 0;
  border-bottom: 1px solid #cecece;
}
@media screen and (max-width: 728px) {
  .seperation-line {
    margin: 10px 0px 10px 0px;
  }
}
.seperation-header {
  background-color: #efefef;
  margin: 10px -24px 5px -24px;
  padding: 10px 24px;
}
.bg-color {
  background: #f7f7f7;
}
.li-bg-color {
  padding: 10px;
  font-family: "Open Sans Condensed", sans-serif;
}
.padding-li-bottom {
  padding-bottom: 10px;
}
ul {
  padding: 20px;
  padding-bottom: 30px;
}
.li-bg-color:hover {
  background: rgba(0,0,0,0.1);
  padding: 10px;
  box-shadow: 8px 10px 5px #b1acac;
}
.li-padding-right {
  padding-right: 28px;
}
.padding-li-bottom-head {
  padding-bottom: 20px;
}
.alertify {
  position: fixed;
  background-color: rgba(0,0,0,0.3);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}
.alertify.hide {
  opacity: 0;
  pointer-events: none;
}
.alertify,
.alertify.show {
  box-sizing: border-box;
  transition: all 0.33s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.alertify,
.alertify * {
  box-sizing: border-box;
}
.alertify .dialog {
  padding: 12px;
}
.alertify .dialog,
.alertify .alert {
  width: 100%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.alertify .dialog > *,
.alertify .alert > * {
  width: 400px;
  max-width: 95%;
  margin: 0 auto;
  text-align: center;
  padding: 12px;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.14), 0 4px 5px 0 rgba(0,0,0,0.098), 0 1px 10px 0 rgba(0,0,0,0.084);
}
.alertify .dialog .msg,
.alertify .alert .msg {
  padding: 12px;
  margin-bottom: 12px;
  margin: 0;
  text-align: left;
}
.alertify .dialog input:not(.form-control),
.alertify .alert input:not(.form-control) {
  margin-bottom: 15px;
  width: 100%;
  font-size: 100%;
  padding: 12px;
}
.alertify .dialog input:not(.form-control):focus,
.alertify .alert input:not(.form-control):focus {
  outline-offset: -2px;
}
.alertify .dialog nav,
.alertify .alert nav {
  text-align: right;
}
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button),
.alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button) {
  background: transparent;
  box-sizing: border-box;
  color: rgba(0,0,0,0.87);
  position: relative;
  outline: 0;
  border: 0;
  display: inline-block;
  align-items: center;
  padding: 0 6px;
  margin: 6px 8px;
  line-height: 36px;
  min-height: 36px;
  white-space: nowrap;
  min-width: 88px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 2px;
}
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):hover,
.alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):hover,
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):active,
.alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):active {
  background-color: rgba(0,0,0,0.05);
}
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):focus,
.alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):focus {
  border: 1px solid rgba(0,0,0,0.1);
}
.alertify .dialog nav button.btn,
.alertify .alert nav button.btn {
  margin: 6px 4px;
}
.alertify-logs {
  position: fixed;
  z-index: 100;
}
.alertify-logs.bottom,
.alertify-logs:not(.top) {
  bottom: 16px;
}
.alertify-logs.left,
.alertify-logs:not(.right) {
  left: 16px;
}
.alertify-logs.left .alertify-log,
.alertify-logs:not(.right) .alertify-log {
  float: left;
}
.alertify-logs.right {
  right: 16px;
}
.alertify-logs.right .alertify-log {
  float: right;
  transform: translateZ(0);
  right: 0;
}
.alertify-logs.right .alertify-log.alertify-log-enter {
  right: -110%;
}
.alertify-logs.right .alertify-log.alertify-log-enter-active {
  right: 0;
}
.alertify-logs.right .alertify-log.alertify-log-leave {
  right: -110%;
}
.alertify-logs.right .alertify-log.alertify-log-enter-active {
  right: 0;
}
.alertify-logs.top {
  top: 0;
}
.alertify-logs .alertify-log {
  padding: 12px;
  color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
  border-radius: 1px;
  box-sizing: border-box;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: relative;
  clear: both;
  backface-visibility: hidden;
  perspective: 1000;
  margin-top: 12px;
  opacity: 1;
  max-height: 1000px;
  padding: 12px;
  pointer-events: auto;
}
.alertify-logs .alertify-log,
.alertify-logs .alertify-log.default {
  background: rgba(0,0,0,0.8);
}
.alertify-logs .alertify-log.error {
  background: rgba(254,0,0,0.8);
}
.alertify-logs .alertify-log.success {
  background: rgba(72,189,31,0.9);
}
.alertify-logs .alertify-log.alertify-log-enter {
  max-height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.alertify-logs .alertify-log.alertify-log-enter-active {
  margin-top: 12px;
  opacity: 1;
  max-height: 1000px;
  padding: 12px;
  pointer-events: auto;
}
.alertify-logs .alertify-log.alertify-log-leave {
  margin-top: 12px;
  opacity: 1;
  max-height: 1000px;
  padding: 12px;
  pointer-events: auto;
}
.alertify-logs .alertify-log.alertify-log-leave-active {
  max-height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.notification-banners {
  position: absolute;
  width: 100%;
}
.notification-banner {
  background-color: #fe0000;
  color: #fff;
}
.notification {
  width: 100%;
  display: flex;
}
.notification span {
  flex: 1 0 auto;
}
.notification button {
  flex: 0 0 auto;
  background-color: transparent;
  color: #fff;
}
.rc-tooltip-inner {
  background-color: #222;
}
.rc-tooltip {
  font-size: 12px;
  max-width: 40%;
}
.tip {
  position: absolute;
  width: 100px;
  color: #fff;
  background: #8d8d8d;
  height: 30px;
  line-height: 30px;
  text-align: center;
  visibility: hidden;
  font-weight: 900;
  border-radius: 15px;
}
.next-button:hover + .tip-next,
.tip-next:hover {
  visibility: visible;
  opacity: 0.8;
  top: 6.5rem;
  right: 4.5rem;
  z-index: 999;
}
.previous-button:hover + .tip-previous,
.tip-previous:hover {
  visibility: visible;
  opacity: 0.8;
  top: 6.5rem;
  left: 4.5rem;
  z-index: 999;
}
@media (max-width: 767px) {
  .header-font {
    font-size: 16px;
    bottom: 0px;
    margin-left: 25px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .fx-ai-con {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .fx-ai-title {
    display: flex;
    align-items: center;
  }
  .nav-menu-btn {
    padding: 6px;
    background: #000;
    margin-right: 10px;
    font-size: 17px;
    position: fixed;
    left: 8px;
    border-radius: 4px;
  }
}
@media (min-width: 768px) {
  .header-font {
    font-size: 28px;
    bottom: 0px;
    margin-left: 25px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .nav-menu-btn {
    padding: 6px;
    background: #000;
    margin-right: 10px;
    font-size: 17px;
    position: fixed;
    top: 23px;
    left: 8px;
    border-radius: 4px;
  }
}
.header-container {
  padding: 18px 5px 18px 5px;
  margin: 0;
  position: fixed;
  z-index: 1011 !important;
  width: 100%;
  border-bottom: 2px solid #ddd;
}
.hide-header {
  z-index: 0;
}
.inline-display {
  display: inline-block;
}
.relative-position {
  position: relative;
}
.header-setting-button {
  font-size: 20px;
  background-color: #fff;
}
.header-brand {
  left: 3rem;
  top: 0rem;
}
.header-brand-padding {
  padding-left: 20px !important;
}
.header img {
  float: left;
  width: 100px;
  height: 100px;
  background: #555;
}
.logo {
  width: 200px;
  height: 40px;
}
.header-name-padding {
  padding: 7px;
}
.menu-icons {
  width: 7%;
}
.top-align {
  float: right;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-right: 48px;
  font-size: 15px;
  padding-top: 15px;
}
@media screen and (max-width: 1024px) {
  .top-align {
    padding-right: 2rem;
  }
}
.header-name {
  float: right;
}
.header-user-name {
  display: inline;
  line-height: 15px;
  padding: 0px 15px;
}
@media screen and (max-width: 600px) {
  .header-user-name {
    display: none;
  }
}
.bg-header {
  background-color: #000;
}
.header-menu-btn {
  float: right;
}
.hidden-text {
  color: #000 !important;
  font-size: 12px !important;
  text-align: center;
  letter-spacing: 1px;
  margin-top: 10px;
  padding-left: 7px;
}
.nav-menu-btn:hover {
  border: 1px solid #fff;
}
.navbar-close-btn {
  position: absolute;
  right: 3px;
  top: 18px;
  font-size: 18px;
}
.brand-font-size {
  font-size: 85%;
}
#cartCount {
  font-size: 12px;
  background: #ff4500;
  color: #fff;
  padding: 0 8px;
  margin-right: -17px;
  float: right;
  margin-right: -21px;
}
.badge-cart {
  border-radius: 12px;
}
.donut .title {
  font-weight: 900;
}
.donut .legend-label {
  font-weight: 700;
  font-size: 20px;
  fill: #8d8d8d;
}
.donut .legend-label-lg {
  font-weight: 700;
  font-size: 29px;
}
.donut .center-text {
  font-weight: 800;
  font-size: 21px;
}
.ag-theme-balham .ag-header {
  border-bottom: 0;
}
.ag-theme-balham .ag-header .ag-header-row:nth-child(2) {
  background-color: #ddd;
}
.ag-theme-balham .ag-header .ag-header-row {
  color: #111;
  font-weight: bold;
  font-size: 14px;
}
.ag-theme-balham .ag-header .ag-header-cell::after,
.ag-theme-balham .ag-header .ag-header-group-cell::after {
  border-right: none;
}
.ag-theme-balham .ag-header .ag-header-cell-label {
  justify-content: center;
}
.ag-theme-balham .ag-row {
  border-color: transparent;
}
.ag-theme-balham .ag-row .ag-cell {
  font-weight: bold;
  font-size: 14px;
  color: #707070;
  border-color: transparent;
}
.ag-theme-balham .ag-row-odd {
  background-color: #f5f5f5;
}
.ag-theme-balham .ag-row-selected {
  background: #bfbfbf;
}
.ag-theme-balham .ag-row-hover {
  background-color: #e5e5e5;
}
.ag-theme-balham .ag-paging-panel {
  justify-content: space-between;
  margin-right: 16px;
  padding: 0px;
  border: none;
  color: #000;
}
.ag-theme-balham .ag-root {
  border: none;
  border-top: 1px solid #bdc3c7;
}
.ag-theme-balham .ag-side-bar {
  border: none;
}
.ag-theme-balham .ag-status-bar {
  border: none;
}
.alert-icon {
  color: #fe0000;
}
.ag-theme-balham .ag-header-cell-label {
  width: 100%;
}
.ag-theme-balham .ag-row {
  border-color: #d9dcde;
}
.span-width {
  margin-left: 22px;
}
.custom-height {
  overflow: hidden;
  height: 29rem;
}
@media screen and (max-height: 714px) and (min-height: 700px) {
  .custom-height {
    height: 15rem;
  }
}
@media screen and (max-height: 750px) and (min-height: 715px) {
  .custom-height {
    height: 16rem;
  }
}
@media screen and (max-height: 799px) and (min-height: 751px) {
  .custom-height {
    height: 19rem;
  }
}
@media screen and (max-height: 850px) and (min-height: 800px) {
  .custom-height {
    height: 22rem;
  }
}
@media screen and (max-height: 900px) and (min-height: 851px) {
  .custom-height {
    height: 26rem;
  }
}
@media screen and (max-height: 1000px) and (min-height: 901px) {
  .custom-height {
    height: 30rem;
  }
}
@media screen and (max-height: 1200px) and (min-height: 1001px) {
  .custom-height {
    height: 55rem;
  }
}
@media screen and (min-height: 1201px) {
  .custom-height {
    height: 55rem;
  }
}
.test-detail-height {
  height: 15rem;
}
.ag-row-no-animation .ag-row {
  transition: none;
}
.ag-header-cell-label {
  text-overflow: clip;
  overflow: visible;
  white-space: normal;
}
.ag-row .ag-cell {
  justify-content: center; /* align horizontal */
  align-items: center;
  text-align: center;
}
.admin-grid-view .ag-layout-normal {
  height: calc(100vh - 42vh);
}
.ag-root-wrapper.ag-layout-normal {
  height: 100%;
}
.ag-theme-balham .ag-header-cell-label .ag-header-cell-text {
  white-space: normal;
}
.custom-height-lg {
  height: 31rem;
}
.custom-height-material {
  overflow: hidden;
  height: 100%;
  display: grid;
}
.upload-height {
  height: 15rem;
}
.cell-wrap {
  white-space: normal !important;
}
.grid-height-adjust {
  height: 100%;
}
.ag-root-wrapper-body.ag-layout-normal {
  flex: 0 0 !important;
  height: auto;
  min-height: auto;
}
.dashboard .status-area {
  vertical-align: top;
  padding: 2rem 0 0 0;
}
.dashboard .status-box {
  padding: 0.5rem 0;
}
.dashboard .chart-area {
  border-left: 1px solid #8d8d8d;
  padding: 0 5rem;
}
.dashboard .p0-top-sm {
  padding: 0 1px 1px 1px;
}
.dashboard .m0-sm {
  margin: 0;
}
.dashboard .lab {
  margin-left: -36px;
  margin-right: -34px;
}
.dashboard-button {
  display: inline-block;
}
@media screen and (max-width: 640px) {
  .dashboard-button {
    display: inline-block;
    width: 21%;
    margin: 0 2%;
    padding: 5px 10px;
  }
}
.comment-container {
  height: 100%;
  padding: 0;
}
.comment-section {
  height: calc(100vh - 235px);
  overflow: auto;
  width: 100%;
}
.log-section {
  height: calc(100vh - 100px);
  overflow: auto;
  width: 100%;
}
.comment-box {
  width: 100%;
  position: relative;
  margin-bottom: 12px;
  display: block;
}
.comment-head {
  background: #e5e5e5;
  padding: 0px 10px;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}
.comment-content {
  background: #fafafa;
  padding: 2px 10px;
  border: 1px solid #e5e5e5;
  font-size: 15px;
  color: #595959;
  border-radius: 0 0 4px 4px;
}
.comment-foot {
  background: #e5e5e5;
  padding: 0px;
  overflow: hidden;
  border-radius: 0 0 4px 4px;
  display: block;
  min-height: 30px;
  padding-left: 10px;
}
.textareaComment {
  min-height: 60px;
  border: 1px solid #e5e5e5;
  background: #fefefe;
  box-shadow: none;
  display: block;
  width: 100%;
}
.comment-add-button {
  display: block;
  float: right;
  background-color: #000;
  border: none;
  color: #fff;
  border-radius: 0 0 5px 0;
  padding: 5px 10px;
}
.score-card-divider {
  width: 20%;
  border-left: 1px solid #dedede;
}
@media screen and (max-width: 640px) {
  .score-card-divider {
    border-top: 1px solid #dedede;
  }
}
.score-card-divider:nth-child(1),
.score-card-divider:nth-child(2) {
  border-top: none !important;
}
.score-card-divider:nth-child(1) {
  border-left: none !important;
}
@media screen and (max-width: 640px) {
  .score-card-divider:nth-child(odd) {
    border-right: 1px solid #dedede;
    border-left: none;
  }
}
@media screen and (min-width: 640px) {
  .score-card-divider:nth-child(odd) {
    border-left: 1px solid #dedede;
  }
}
.border-solid-light-grey {
  border: 1px solid #efefef;
}
.ncss-btn-primary-dark {
  padding: 5px 24px;
  border-radius: 20px;
  background: #111;
  color: #fff;
}
.text-color-error {
  color: #fa5400;
  font-weight: normal;
  font-size: smaller;
}
.header-brand {
  padding-left: 85px !important;
}
.search-Button {
  position: absolute;
  bottom: 11px;
  background: #fff;
  right: 787px;
  font-size: 20px;
  color: #e7e7e9;
  cursor: default;
}
.search-container {
  margin-left: 18%;
}
.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  background-color: #fff;
  margin: 0 auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  width: 75%;
  border: 3px solid #2c3e50 !important;
}
@media screen and (max-width: 1024px) {
  .modal-container {
    top: 54%;
    height: 93%;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .modal-container {
    top: 56%;
    height: 88%;
    width: 100%;
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 12;
}
.modal-close-btn {
  background: transparent;
  float: right;
  font-size: 20px;
}
.modal-right-container {
  position: fixed;
  top: 44px;
  right: 0;
  background-color: #fff;
  margin: 0;
  padding: 10px 20px;
  width: 50%;
  height: 100vh;
}
@media screen and (max-width: 640px) {
  .modal-right-container {
    width: 90%;
    top: 40px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 640px) {
  .modal-right-container {
    width: 60%;
    top: 40px;
  }
}
.decorationList {
  display: flex;
  flex-wrap: wrap;
}
.scrollable-modal {
  overflow-y: auto;
}
.padding-popup {
  margin-left: 25px;
}
.grey-button-icon-popup {
  background-color: rgba(0,0,0,0.8);
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  padding: 1px 5px;
/* border-radius: 15%; */
  margin-bottom: 5px;
}
.ul-font-size {
  font-size: 14px;
  font-weight: 700;
}
.rTable {
  display: table;
}
.rTableRow {
  display: table-row;
  text-align: center;
}
.rTableHeading {
  display: table-header-group;
}
.rTableBody {
  display: table-row-group;
}
.rTableFoot {
  display: table-footer-group;
}
.rTableCell,
.rTableHead {
  display: table-cell;
}
.react-calendar {
  width: 250px !important;
  max-width: 100% !important;
  z-index: 3000;
  position: relative !important;
  background-color: #fff !important;
  border: 1px solid #000;
}
.react-calendar__navigation__label,
.react-calendar__navigation__arrow {
  font-weight: 600;
  font-size: 10px;
}
.react-calendar__navigation {
  border-bottom: 1px solid #a0a096;
}
.react-calendar__tile {
  border-radius: 5px;
}
.react-calendar__tile--active {
  background: #3498db;
  font-weight: 900;
}
.react-date-picker__wrapper {
  display: flex;
  border: thin solid #808080;
}
.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  display: flex;
  padding: 0 2px;
  align-items: baseline;
  box-sizing: content-box;
}
.react-calendar__decade-view {
  padding: 0.3rem;
}
.react-calendar__tile {
  font-size: 12px;
  flex-basis: 30.3333% !important;
  margin: 1px !important;
}
.react-date-picker__calendar {
  position: relative !important;
  height: 100% !important;
}
.react-date-picker__calendar--closed {
  display: none;
}
.react-date-picker__clear-button {
  display: none;
}
.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  width: 100% !important;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
}
.DayPicker-Border input {
  border: 1px solid #000;
  padding: 4px 7px;
  border-radius: 0;
  width: auto !important;
  max-width: 150px;
}
.DayPicker-Day--monday {
  color: #00bcd4;
}
.DayPicker-Day {
  padding: 2px 0.5em;
}
.react-date-picker__calendar-button__icon {
  vertical-align: middle;
}
.dashboard {
  margin: auto;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media screen and (max-height: 1000px) {
  .dashboard h1 {
    font-size: 1.8rem;
  }
}
@media screen and (max-height: 1000px) {
  .dashboard H4 {
    font-size: 0.9rem;
    margin: 12px 0px 12px 0px;
  }
}
.card-title {
  z-index: 4;
  position: absolute;
  font-weight: 700;
  font-size: 3.5rem;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  color: rgba(0,0,0,0.75);
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .card-title {
    font-size: 2.5rem;
    color: rgba(0,0,0,0.75);
    transition: all 0.4s ease-out;
  }
}
@media screen and (min-width: 768px) and (max-width: 916px) {
  .card-title {
    font-size: 2.5rem;
    color: rgba(0,0,0,0.75);
    transition: all 0.4s ease-out;
  }
}
@media screen and (min-width: 916px) and (max-width: 1023px) {
  .card-title {
    font-size: 3rem;
    color: rgba(0,0,0,0.75);
    transition: all 0.4s ease-out;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .card-title {
    font-size: 3.5rem;
    color: rgba(0,0,0,0.75);
    transition: all 0.4s ease-out;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .card-title {
    font-size: 3rem;
    color: rgba(0,0,0,0.75);
    transition: all 0.4s ease-out;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1679px) {
  .card-title {
    font-size: 3rem;
    color: rgba(0,0,0,0.75);
    transition: all 0.4s ease-out;
  }
}
@media screen and (min-width: 1680px) and (max-width: 1900px) {
  .card-title {
    font-size: 3.5rem;
    color: rgba(0,0,0,0.75);
    transition: all 0.4s ease-out;
  }
}
.view {
  float: right;
  top: 0;
  left: 0;
  height: 15rem;
}
.view .card-img-top {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  object-fit: contain;
  filter: blur(2px) opacity(70%);
  transition: all 0.4s ease-in-out;
}
@media screen and (max-height: 600px) {
  .view .card-img-top {
    width: 70% !important;
    height: 70% !important;
  }
}
@media screen and (max-height: 700px) {
  .view .card-img-top {
    width: 80% !important;
    height: 80% !important;
  }
}
@media screen and (max-height: 800px) {
  .view .card-img-top {
    width: 80% !important;
    height: 80% !important;
  }
}
@media screen and (max-height: 1000px) {
  .view .card-img-top {
    width: 100% !important;
    height: 100% !important;
  }
}
.view .blur {
  display: none;
  transition: all 0.4s ease-in-out;
}
@media screen and (max-height: 600px) {
  .view .blur {
    width: 70% !important;
    height: 70% !important;
  }
}
@media screen and (max-height: 700px) {
  .view .blur {
    width: 80% !important;
    height: 80% !important;
  }
}
@media screen and (max-height: 800px) {
  .view .blur {
    width: 90% !important;
    height: 80% !important;
  }
}
@media screen and (max-height: 1000px) {
  .view .blur {
    width: 100% !important;
    height: 100% !important;
  }
}
.view:hover .card-img-top {
  filter: blur(0px);
  transition: all 0.4s ease-in-out;
  position: absolute;
  clip: rect(98px, 500px, 500px, 0px);
}
@media screen and (max-height: 1000px) {
  .view:hover .card-img-top {
    clip: rect(85px, 500px, 500px, 0px);
  }
}
.view:hover .blur {
  transition: all 0.4s ease-in-out;
  display: flex;
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  clip: rect(0px, 500px, 100px, 0px);
  filter: blur(2px) opacity(50%);
}
.view:hover .card-title {
  transition: all 0.4s ease-in-out;
  transform: translateY(-1rem);
  color: rgba(0,0,0,0.8);
}
.card {
  width: 100%;
  border: 1px solid #cacfd2;
  font-weight: bold;
  color: #000;
  box-shadow: 5px 5px 5px #b1acac;
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
@media screen and (min-height: 500px) and (max-height: 700px) {
  .card {
    width: 86%;
    height: calc(100vh - 79vh);
    margin-right: 5%;
    margin-left: 10%;
  }
}
@media screen and (min-height: 701px) and (max-height: 1600px) {
  .card {
    width: 90%;
    height: calc(100vh - 79vh);
    margin-right: 5%;
    margin-left: 10%;
  }
}
.dashboard p {
  font-weight: normal;
/* Detailed Card */
}
.details-card {
  position: relative;
  width: 100%;
  height: 400px;
  border: 1px solid #cacfd2;
  font-weight: bold;
  color: #000;
  box-shadow: 5px 5px 5px #b1acac;
  overflow: hidden;
  margin-bottom: 15px;
  margin-top: 40px;
}
.airbag-img-carousel .carousel .slide {
  background-color: #fff;
}
.airbag-img-carousel {
  padding: 14px 10px 0;
}
.image-carousel .carousel .slide {
  background-color: #fff;
}
.airbag-img {
  width: 100% !important;
  height: auto !important;
  position: absolute;
  padding: 30px 20px 0px 20px;
  top: 51px;
}
.card-list {
  width: 100%;
}
.card-list td {
  padding: 5px 0px 5px 1rem;
  font-size: 14px;
  color: #000;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  text-transform: capitalize;
  table-layout: fixed;
  width: 50%;
}
.listcard-items {
  width: 50%;
}
.bag-filter-card {
  background: #ddd;
  font-size: 14px;
  font-weight: bold;
}
.card-space-adjust {
  margin-bottom: 20px;
}
.tooltip-img {
  width: 100% !important;
  height: auto !important;
  border: 1px solid #34495e;
  position: absolute;
}
.tool-tip-info {
  position: absolute;
  top: 0;
  left: 52%;
}
.tool-tip-container {
  position: relative;
}
.__react_component_tooltip {
  width: 500px !important;
  height: 300px !important;
  box-shadow: 5px 5px 5px #b1acac;
}
.tooltip-heading {
  font-size: 2rem;
  font-weight: 900;
}
.tooltip-type {
  font-size: 1.5rem;
  font-weight: 900;
  color: #8d8d8d;
  -webkit-text-fill-color: #8d8d8d;
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: #000;
}
.tooltip-size {
  font-size: 1.5rem;
  font-weight: 600;
}
.tooltip-listcard {
  min-width: 50px !important;
  max-width: 200px !important;
  margin-top: 1px !important;
  height: auto !important;
  word-break: break-all;
  overflow-wrap: break-word;
  display: block;
}
.size {
  background-color: #2c3e50;
  color: #fff;
  height: 30px;
  width: 100%;
  border-radius: 20px;
  font-size: 17px !important;
  font-weight: 600;
  padding-left: 15px;
}
.airbag-heading {
  font-size: 28px;
  color: #fff;
  opacity: 1 !important;
  width: 100%;
  background: rgba(0,0,0,0.8);
  padding: 10px;
  z-index: 10;
}
.airbag-size {
  float: right;
  opacity: 1 !important;
}
.airbag-price {
  font-size: 15px;
}
.airbag-fabheight {
  float: right;
  opacity: 1 !important;
  word-wrap: break-word;
  max-width: 75%;
  min-width: unset;
  font-size: 15px;
}
.airbag-details {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0,0,0,0.8);
  color: #fff;
  font-size: 17px;
  padding: 2px;
/*...................Modal Styles.........................*/
}
.separator {
  margin-top: 5px;
  border: 1px solid #c3c3c3;
}
.modal-button {
  padding: 0.6rem 1.2rem;
  position: absolute;
  background: rgba(0,0,0,0.6);
  width: 75%;
  color: #fff;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  font-weight: 700;
  display: none;
}
.modal-buttons {
  background-color: #808080;
  border: none;
  color: #fff;
  padding: 5px;
  text-align: center;
  display: inline-block;
  font-size: 13px;
  float: right;
  font-weight: bold;
  font-family: 'Open Sans Condensed', sans-serif;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  margin-top: -11px;
}
.modal-button:hover {
  background: #000;
  color: #fff;
}
.modal-button:hover .modal-area:hover {
  background: transparent;
}
.modal-area {
  widows: 100%;
  height: 100%;
  background: #fff;
}
.modal-area:hover {
  background: rgba(242,242,242,0.5);
}
.modal-area:hover .modal-button {
  display: inline;
  border-radius: 3px;
  box-shadow: 0px 8px 15px rgba(0,0,0,0.1);
  transition: all 0.3s ease 0s;
}
.modal-area:hover .modal-img {
  opacity: 0.5;
}
.airbag-link {
  height: 100%;
}
.airbag-modal-container {
  position: relative;
  padding-bottom: 25px;
  height: 70vh;
}
.airbag-modal-img {
  border: 1px solid rgba(225,225,225,0.3);
  box-shadow: 8px 10px 5px #b1acac;
/* min-height: 30vh; */
  min-width: 34.7vw;
  max-height: 60vh;
  padding: 10vh;
}
.airbag-modal-carousal {
  margin-top: 60px;
  margin-left: 30px;
}
.airbag-modal-img-cart {
  border: 1px solid rgba(225,225,225,0.3);
  box-shadow: 8px 10px 5px #b1acac;
}
.airbag-modal-info {
  position: absolute;
  top: 0;
  left: 50%;
  width: 47%;
}
.airbag-modal-heading {
  left: 60%;
  font-size: 1.7rem;
  font-weight: 900;
  background: rgba(0,0,0,0.8);
  color: #fff;
  box-shadow: 8px 10px 5px #b1acac;
}
.airbag-modal-type {
  font-size: 1.5rem;
  font-weight: 900;
  color: #fff;
  background: rgba(0,0,0,0.8);
  padding-bottom: 4px;
  box-shadow: 8px 10px 5px #b1acac;
  text-transform: uppercase;
}
.airbag-modal-size {
  font-size: 1.5rem;
  font-weight: 600;
}
.details-view {
  text-align: left;
  margin-top: 10px;
  margin-left: 10px;
}
.details-heading,
.details-description {
  font-size: 1.2rem;
  font-weight: 600;
}
.details-heading .details-heading,
.details-description .details-heading {
  width: 40%;
}
.details-description {
  float: right;
  width: 60%;
  word-break: break-word;
}
@media screen and (max-width: 768px) {
  .details-description {
    width: 50%;
  }
}
.background-details-modal {
  background: #ddd;
  box-shadow: 8px 10px 5px #b1acac;
  padding: 20px 10px 30px 10px;
  overflow: auto;
}
.details-description-button {
  font-size: 15px;
  margin-top: 80%;
  float: left;
  padding: 5px;
  color: #fff;
  background: rgba(0,0,0,0.8);
  padding-left: 15px;
  padding-right: 15px;
}
.text-button-modal {
  font-family: "Open Sans Condensed", sans-serif;
  margin-left: 5px;
}
.size-background {
  background: rgba(0,0,0,0.8);
  padding: 2px;
  margin: 2px;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap;
  font-size: 11px;
  text-align: center;
}
.not-available {
  padding-left: 5px;
  font-size: 1.2rem;
  text-align: center;
}
.details-heading-size {
  font-size: 1.2rem;
  font-weight: 600;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
download-button {
  padding-left: 6px;
}
.fa.fa-download:hover {
  color: rgba(0,0,0,0.8);
  cursor: pointer;
  font-size: 1.5em;
}
.test-img-class {
  height: auto !important;
  width: 100% !important;
}
.collectionalert {
  margin: 0 auto;
  width: 65%;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  border: 4px solid #cacfd2;
  color: #000;
  box-shadow: 5px 5px 5px #b1acac;
  background: #ddd;
  margin-bottom: 10px;
}
.collectionalert .collection-item.desc-card {
  min-height: 84px;
  position: relative;
}
.collectionalert .collection-item {
  display: flex;
  background-color: #fff;
  padding: 15px 0px;
  margin: 0;
}
.collectionalert .collection-item img {
  width: 80px;
  height: 80px;
  margin-right: 25px;
}
.align-Items {
  padding-right: 20px;
}
.modal-check-btn {
  background: transparent;
  float: left;
  font-size: 35px;
}
.margin-extra {
  margin-top: 35px;
  margin-left: 15px;
}
@media (max-width: 1024px) {
  .airbag-modal-info {
    position: absolute;
    width: 100%;
    left: 0%;
    top: auto;
    padding-bottom: 36px;
  }
  .margin-extra {
    margin-top: 15px;
    margin-left: 15px;
  }
}
.container-left {
  width: 40%;
}
.container-right {
  width: 56%;
  margin-left: 20px;
}
.cardContainer-outer {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-around;
}
.invalid-res {
  color: #000;
  text-align: center;
  font-size: 34px;
  padding-left: 20px;
}
.invalid-message {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  padding-left: 3px;
}
.form-Container {
  margin: auto;
  overflow-y: scroll;
  height: 44vh;
}
.float-right {
  float: right;
}
.text {
  text-align: center;
  padding: 15px;
  margin: 20px;
}
@media (max-width: 1024px) {
  .text {
    margin: 0px;
  }
}
.text-color-red {
  color: #f00;
}
.button-align-right {
  text-align: right;
  width: 100%;
  padding: 0;
}
.decoration-price-textbox {
  min-height: 25px !important;
  margin: 4px;
}
.link-text {
  color: #0758a7;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}
.new_item_textbox {
  min-height: 32px !important;
  margin: 4px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #111;
}
input:focus + .slider {
  box-shadow: 0 0 1px #111;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
Button:disabled {
  background: #808080;
}
.addProductModal {
  height: 70vh;
  overflow-y: hidden;
  width: 75%;
  background: #e8e8e8;
}
@media screen and (max-width: 767px) {
  .addProductModal {
    height: 60vh;
  }
}
.decorationModal {
  height: 70vh;
  overflow-y: auto;
  width: 75%;
  background: #e8e8e8;
}
.decorationModal::-webkit-scrollbar {
  width: 10px;
}
.decorationModal::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin: 5px;
}
.decorationModal::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.decorationModal::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.season-add-new {
  padding: 4px;
}
.bg-addproduct {
  background: rgba(152,152,152,0.2);
}
.upload-button {
  border: 1px solid #ccc;
  font-size: 12px;
}
.multiple-image-options {
  border: 1px solid #000;
  margin-top: -21px;
  margin-left: 8px;
  margin-right: 8px;
  width: 98.1%;
}
@media screen and (max-width: 1680px) {
  .multiple-image-options .form-Container {
    height: 51vh;
  }
}
@media screen and (max-width: 1366px) {
  .multiple-image-options .form-Container {
    height: 44vh;
  }
}
@media screen and (max-width: 1280px) {
  .multiple-image-options .form-Container {
    height: 44vh;
  }
}
@media screen and (max-width: 1024px) {
  .multiple-image-options .form-Container {
    height: 40vh;
  }
}
.height-dropdown {
  height: 42px !important;
  min-height: 42px !important;
  font-weight: bold;
}
.select__control {
  height: 42px !important;
  min-height: 42px !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.mass-popup-table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}
.mass-table-body {
  overflow-y: auto;
  height: 150px;
}
.mass-popup-th {
  width: 50%;
  position: sticky;
  top: 0;
  background-color: #a0a0a0;
}
.mass-popup-td {
  text-align: left;
  font-size: 14px;
  padding: 8px;
}
tr:nth-child(even) {
  background-color: #f1f1f1;
}
.popup-size {
  font-size: 20px;
}
.mt-15 {
  margin-top: 25px;
}
.mb-15 {
  margin-bottom: 15px;
}
.bag-container {
  margin-top: 86px;
}
.bag-header {
  display: inline-block;
  box-sizing: border-box;
  float: right;
  padding-right: 10rem;
  width: 100%;
  border: 1px solid rgba(225,225,225,0.3);
  padding: 30px 20px 20px;
}
.bag-title {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  background: rgba(0,0,0,0.8);
  color: #fff;
  padding-left: 5px;
  padding-top: 5px;
  margin-bottom: 20px;
}
.bag-ul {
  background: #ddd;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 10px;
  font-weight: bold;
}
.bag-sizing {
  vertical-align: top;
  padding-right: 16px;
}
.bag-div {
  float: right;
  margin-top: 87px;
  background: #fff;
  box-shadow: 5px 5px 5px #b1acac;
}
.bag-cart {
  display: flex;
  background: #fb641b;
  box-shadow: 0 1px 2px 0;
}
.bag-top {
  text-align: center;
  font-weight: bold;
  background: rgba(0,0,0,0.8);
  color: #fff;
  padding-bottom: 5px;
}
.align-code {
  float: right;
}
.img-top {
  text-align: center;
  color: #000;
  font-weight: bold;
  background: #ddd;
  padding: 2px;
  font-size: 20px;
}
.bag-ul li span {
  color: #000;
  float: right;
}
.shadow-class {
  box-shadow: 8px 10px 5px #b1acac;
  text-transform: uppercase;
}
.topbar-height {
  height: 57px !important;
  top: 85px !important;
}
.arrow-bag {
  margin-top: 15px;
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.arrow-span {
  padding-left: 6px;
  font-size: 14px;
  color: #000;
  padding-top: 0px;
  margin-bottom: 5px;
  font-weight: bold;
  text-decoration: underline;
}
.arrow-div {
  position: fixed;
  background: #ddd;
  z-index: 99;
  top: 92px;
  width: 100%;
  left: 0;
  padding: 0 16px;
  border-bottom: 2px solid rgba(0,0,0,0.4);
}
.fixed-search {
  position: fixed;
  top: 89px;
  background-color: #ddd;
  height: 83px;
  padding-top: 16px;
  padding-right: 22%;
  padding-bottom: 10px;
  z-index: 11;
  border-bottom: 2px solid rgba(0,0,0,0.4);
}
.push-right-search {
  right: 3px;
}
.push-right-search-airbag {
  right: 0px;
}
.list-top-adjust {
  margin-top: 84px;
}
.arrow-back {
  position: fixed;
  top: 89px;
  left: 0;
  z-index: 12;
  background: #ddd;
  padding-top: 14px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(0,0,0,0.4);
  height: 83px;
}
.back-search {
  padding-right: 9%;
}
.tooltip {
  min-width: 50px !important;
  max-width: 200px !important;
  height: 34px !important;
  margin-top: 1px !important;
}
.thumbdiv {
  margin-top: 20px;
  border: 1px solid rgba(225,225,225,0.3);
  box-shadow: 8px 10px 5px #b1acac;
  padding: 0;
}
.modal-prod {
  border: 1px solid rgba(225,225,225,0.3);
  padding: 30px 20px 20px;
  max-height: 86px;
  max-width: 100px;
}
.modal-dis {
  display: flex;
  margin-left: 50px;
  align-items: center;
  padding: 47px 0;
}
.prod-images {
  max-width: 368px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.prod-heading {
  font-size: 24px;
}
.prod-result {
  margin-top: 19px;
  margin-left: 37%;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .prod-result {
    margin-left: 20%;
  }
}
.attach-name {
  position: relative;
  right: 59px;
  word-break: break-word;
}
.attach-icon {
  padding-left: 17px;
}
@media screen and (max-width: 2040px) {
  .bag-header {
    padding: 15px 80px 5px 80px;
  }
}
@media screen and (min-width: 2040px) {
  .bag-header {
    padding: 15px 150px 5px 150px;
    max-height: 100%;
  }
}
@media (max-width: 767px) {
  .push-right-search {
    right: 44px;
  }
}
@media (max-width: 1024px) {
  .image-container {
    width: 100% !important;
  }
  .m-10 {
    margin-left: 0px;
  }
  .bag-div {
    margin-top: 16px;
    box-shadow: 5px 5px 5px #b1acac;
    float: left;
    width: 100%;
  }
  .bag-sizing {
    vertical-align: top;
    padding-right: 16px;
    width: 100%;
  }
  .pt-0 {
    padding-top: 0px;
  }
  .pl-0 {
    padding-left: 0px;
  }
  .pt-10 {
    padding-top: 10px;
  }
  .pr-0 {
    padding-right: 0px;
  }
}
.button-agrid {
  color: #fff;
  font-size: inherit;
  text-transform: uppercase;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
  border-radius: 15px;
  white-space: nowrap;
}
.size-bubble-background {
  background: rgba(0,0,0,0.8);
  padding: 2px;
  margin: 2px;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 11px;
  text-align: center;
}
.icon-size-adjust {
  font-size: 1.3rem;
  color: rgba(0,0,0,0.8);
  cursor: default;
}
.btn-color-blue {
  background: #0078b9;
}
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}
.dot-active,
.text-color-green {
  color: #008000;
}
.dot-inactive {
  color: #f00;
}
.fas.fa-edit:hover {
  color: #0078b9;
  cursor: pointer;
  font-size: 1.5em;
}
.bubble-size {
  width: 38px;
}
.left-text-align {
  text-align: left;
}
.text-excel-color-grid {
  color: #111;
  padding: 3px;
  margin-top: 10px;
  background: #ddd;
  text-decoration: none;
  border: 2px solid #cfcfcf;
  font-size: 11px;
}
.gap {
  margin-left: 10px;
}
.filter_dropdown {
  position: absolute;
  background: #fff;
  z-index: 999;
  top: 34px;
  border: 1px solid rgba(0,0,0,0.5);
  left: 0px;
  padding: 8px 8px;
  top: 41px;
  height: 500px;
  width: 250px;
  left: 0px;
  background: #e5e5e5;
}
.filter_dropdownsearch {
  position: absolute;
  background: #fff;
  z-index: 999;
  top: 34px;
  border: 1px solid rgba(0,0,0,0.5);
  left: 0px;
  padding: 8px 8px;
  top: -40px;
  height: 500px;
  width: 250px;
  left: 0px;
  background: #e5e5e5;
}
li,
.subHeadings {
  padding: 5px 0px;
  font-size: 14px;
  color: #000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: capitalize;
}
.subHeadings {
  color: #fff;
}
.accordion {
  border: none;
}
.accordion__button,
.accordion__button:hover {
  background: rgba(0,0,0,0.8);
  padding: 7px;
}
.accordion__button:before {
  border: 6px solid transparent;
  border-left-color: #fff;
  transform: rotate(0deg);
}
.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(90deg);
  top: 4px;
  position: relative;
}
.search-input input {
  width: 100%;
  padding: 6px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}
.filter_icon:before {
  color: #fff;
}
.filter-dashboard-position {
  position: absolute;
  left: 73%;
  top: 1px;
  padding: 23px;
  z-index: 11;
}
.filter-search-position {
  position: absolute;
  right: 28px;
  top: 33px;
  height: 48px !important;
}
.airpage-filtepos {
  position: absolute;
  left: 98%;
  top: 2px;
  padding: 23px;
  z-index: 11;
}
.height-filter-scroll {
  max-height: 350px !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.height-filter-scroll-res {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-top: 10px;
}
.filter-position {
  float: right;
  top: 110px;
  left: 200px;
  position: fixed;
  z-index: 11;
}
.filter-dashboard {
  background: #333 !important; /* padding-top: 48px; */
  padding-bottom: -35px;
  height: 45px;
  width: 90px;
  font-weight: bold;
  border-radius: 0px 30px 30px 0px;
  border: 2px solid #333 !important;
}
.filter-push-right {
  right: 10px;
}
.filter {
  background: #333 !important;
  height: 39px;
  width: 90px;
  font-weight: bold;
  border-radius: 0px 30px 30px 0px;
}
i {
  cursor: Pointer;
}
.clr_all_btn {
  background: none;
  font-weight: 600;
}
.filter_icon_dashboard {
  position: absolute;
  float: right;
  left: 9px;
  top: 14px;
  color: #fff;
  cursor: pointer;
}
.filter_icon {
  position: absolute;
  float: right;
  left: 9px;
  top: 14px;
  color: #fff;
  cursor: pointer;
}
.clear_filter {
  border: 1px solid #808080;
  color: #fff;
  position: absolute;
  right: 8px;
  bottom: 3px;
  border: 1px solid #808080;
  color: #fff;
  background: #000;
}
.apply_filter {
  border: 1px solid #808080;
  color: #fff;
  position: absolute;
  border: 1px solid #808080;
  color: #fff;
  background: #000;
}
.filter-name-dashboard {
  position: absolute;
  left: 28px;
  top: 6px;
/* letter-spacing: 0.5px; */
  font-size: 18px;
  color: #fff;
}
.filter_name {
  position: absolute;
  left: 28px;
  top: 5px;
/* letter-spacing: 0.5px; */
  font-size: 18px;
  color: #fff;
}
.accordion__panel {
  padding: 0;
  position: relative;
}
.push_right {
  float: right;
}
.push_left {
  float: left;
}
.text_upper {
  text-transform: uppercase;
}
.price_range {
  position: absolute;
  top: 45px;
  left: 0px;
  text-align: center;
  word-spacing: 0.2em;
  width: 100%;
}
.range_label {
  position: absolute;
  top: 35px;
  left: 0px;
  text-align: center;
  word-spacing: 0.2em;
  width: 100%;
}
.range_label_airsole {
  position: absolute;
  top: 35px;
  left: 0px;
  text-align: center;
  word-spacing: 0.2em;
  width: 100%;
}
.range_label_top_fix {
  position: absolute;
  top: 120px;
  left: 0px;
  text-align: center;
  word-spacing: 0.2em;
  width: 100%;
}
.no_result {
  position: absolute;
  top: 48%;
  left: 47%;
  font-weight: 600;
  font-size: 17px;
}
.blc_fab {
  background-color: #000 !important;
  position: absolute !important;
  width: 100px !important;
  height: 100px !important;
  right: 30px;
  bottom: 100px;
}
.blc_fab svg {
  color: #fff;
  font-size: 60px;
}
.filter-modal {
  overflow-y: hidden;
  background: #e8e8e8;
  width: 100%;
  max-height: -webkit-fill-available;
  overflow: scroll;
}
.modal-text {
  color: #000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: capitalize;
}
.chip {
  display: inline-block;
  padding: 0 15px;
  height: 30px;
  font-size: 14px;
  font-weight: 900;
  line-height: 30px;
  color: rgba(0,0,0,0.8);
  background-color: #e6e6e6;
  margin-right: 4px;
  margin-bottom: 4px;
}
.closebtn {
  padding-left: 10px;
  color: rgba(0,0,0,0.8);
  font-weight: bold;
  float: right;
  font-size: 20px;
  cursor: pointer;
}
.closebtn:hover {
  color: #d3dae1;
}
.season-select-component {
  border: 1px solid #ddd;
  border-radius: 4px;
}
.season-select-results {
  border: 1px solid #ddd;
  min-height: 45px;
  padding: 5px;
  background: #fff;
  font-size: 16px;
}
.season-placeholder {
  color: #808080;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination>li {
  display: inline;
}
.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 5px;
  font-weight: bold;
}
.pagination>li:first-child>a,
.pagination>li:first-child>span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
  z-index: 2;
  background-color: #dbdbdb;
  border-color: #ddd;
}
.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: rgba(0,0,0,0.8);
}
.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.pagination-lg>li>a,
.pagination-lg>li>span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.pagination-sm>li>a,
.pagination-sm>li>span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;
}
.pager li {
  display: inline;
}
.pager li>a,
.pager li>span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
}
.pager li>a:focus,
.pager li>a:hover {
  text-decoration: none;
  background-color: #eee;
}
.pager .next>a,
.pager .next>span {
  float: right;
}
.pager .previous>a,
.pager .previous>span {
  float: left;
}
.pager .disabled>a,
.pager .disabled>a:focus,
.pager .disabled>a:hover,
.pager .disabled>span {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
}
.no_result_pagination {
  margin-top: 38%;
}
.text-center {
  text-align: center;
}
.confirm-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 13;
}
.confirm-modal-close-btn {
  background: transparent;
  margin: 5px -15px 0 0;
  float: right;
}
.confirm-close {
  margin-bottom: -7px;
}
.confirm-modal-text {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  padding-bottom: 50px;
}
@media (max-width: 767px) {
  .confirm-modal-text {
    text-align: justify;
    height: 45vh;
  }
}
.bottom-section {
  text-align: center;
}
.popup-bottom {
  float: right;
}
.button-width-adjust {
  width: 8rem;
  padding: 0.4rem 2.5rem;
  margin-right: 10px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .button-width-adjust {
    width: 6.6rem;
    padding: 7px;
  }
}
@media (max-width: 1024px) {
  .confirm-modal-container {
    position: absolute;
    top: 55%;
    left: 50%;
    right: auto;
    bottom: auto;
    background-color: #fff;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    width: 70%;
    border: 5px solid rgba(0,0,0,0.8) !important;
  }
}
@media (min-width: 1025px) {
  .confirm-modal-container {
    position: absolute;
    top: 40%;
    left: 50%;
    right: auto;
    bottom: auto;
    background-color: #fff;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    width: 40%;
    border: 5px solid rgba(0,0,0,0.8) !important;
  }
}
@media (max-width: 767px) {
  .confirm-display {
    display: flex;
    padding-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .confirm-modal-height {
    height: 40vh;
  }
}
​,
.splash-screen {
  width: 100%;
  margin-top: 1rem;
}
​,
.sheet-category {
  font-size: 1.8rem;
  font-weight: 600;
  text-align: left;
  color: #fff;
  padding: 0px 20px;
  background-repeat: no-repeat;
  background-size: 22% 150px;
  background-position: right;
  overflow: visible;
  z-index: inherit;
  padding: 10px;
  text-align: -webkit-center;
  text-align: center;
  word-break: break-word;
  padding-top: 10px;
}
​,
.sheet-bagtype {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  color: rgba(0,0,0,0.7);
}
​,
.sheet_border {
  background-color: #fff;
  background: #f7f7f7;
  border: 1px solid rgba(0,0,0,0.7);
}
.sheet-card {
  position: relative;
  width: 105%;
  height: 160px;
  font-weight: bold;
  color: #000;
  overflow: hidden;
  text-align: center;
  color: #162a44;
  background: #fff;
  padding: 0px;
  margin: 0px;
}
​,
.sheet-card-faq {
  position: relative;
  width: 90%;
  height: 210px;
  font-weight: bold;
  color: #000;
  overflow: hidden;
  text-align: center;
  color: #162a44;
  margin: 0px;
  box-shadow: 1px 5px 5px 5px #b1acac;
  padding-bottom: 60px;
}
​,
.imp-ml-8 {
  margin-left: 8px !important;
}
​,
.sheet-img {
  width: 110px !important;
  height: auto !important;
  margin-left: 4%;
  margin-top: 1px;
  overflow: hidden;
}
​,
.download-position {
  position: fixed;
  top: 90px;
  right: 90px;
}
​,
.sheet_category_container {
  margin: 0 10px;
  background: rgba(234,114,63,0.1);
}
​,
.sheet-code {
  color: #fff;
  background-color: #ea723f;
  font-size: 12px;
}
​,
.pdf-header {
  width: 100%;
  padding: 5px;
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 40px;
  box-shadow: 8px 10px 5px #b1acac;
}
​,
.sheet-price-code {
  font-size: 12px;
}
​,
.icon-solo span {
  color: #3498db;
  font-weight: bold;
}
​,
.hide-display {
  display: none;
}
​,
.rotate-90 {
  -webkit-transform: rotateZ(270deg);
  transform: rotateZ(270deg);
}
​,
.vertical-top {
  vertical-align: top;
}
​,
.bagtype-heading {
  padding: 40px 0 60px 0;
}
.remove-padding {
  padding: 0;
}
​,
.remove-margin {
  margin: 0;
}
.top-border {
  border-top: 1px solid rgba(0,0,0,0.6);
}
​,
.category-border {
  border-top: 1px solid rgba(0,0,0,0.6);
}
​ .sheet-category,
.blueClass .sheet-category {
  color: #fff !important;
  background-color: #428eff !important;
}
​ .sheet-code,
.blueClass .sheet-code {
  background-color: #428eff !important;
}
​ .sheet_category_container,
.blueClass .sheet_category_container {
  background: rgba(66,142,255,0.1);
}
​ .sheet-category,
.greenClass .sheet-category {
  color: #fff !important;
  background-color: #3ca023 !important;
}
​ .sheet-code,
.greenClass .sheet-code {
  background-color: #3ca023 !important;
}
​ .sheet_category_container,
.greenClass .sheet_category_container {
  background: rgba(60,160,35,0.1) !important;
}
.orangeClass .sheet-category {
  color: #fff !important;
  background-color: #fa5400 !important;
}
.orangeClass .sheet-code {
  background-color: #fa5400 !important;
}
.orangeClass .sheet_category_container {
  background: rgba(60,160,35,0.1) !important;
}
​,
.sheet-category-backgorund {
  background-size: 100% 100px;
  height: 100px;
  width: 100%;
  background-repeat: no-repeat;
}
​,
.date-style {
  float: right;
  font-weight: 600;
}
​,
.footwear-style {
  float: left;
  font-weight: 600;
  margin-left: 10px;
  font-size: 25px;
  margin-bottom: -18px;
}
.faq-height {
  height: 200px;
}
.pdf-document {
  width: 100%;
}
.k-pdf-export .ncss-container.fixed-fluid {
  min-width: 100%;
}
.k-pdf-export #divToPrint div {
  font-family: "DejaVu Sans", "Arial", sans-serif;
}
.k-pdf-export #divToPrint .sheet-card {
  height: 160px;
}
.k-pdf-export #divToPrint .sheet-card .sheet-img {
  width: 80px;
}
.k-pdf-export #divToPrint .sheet-category,
.k-pdf-export #divToPrint .sheet-bagtype,
.k-pdf-export #divToPrint .footwear-style {
  font-weight: bold;
}
.k-pdf-export .align-right-pdf {
  float: right;
}
.k-pdf-export .pdf-header {
  padding: 2px;
}
.faq-search-section {
  background-color: #fff;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: 2px solid #ddd;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
}
.header-text {
  color: #0078b9 !important;
  font-size: 40px;
  text-align: center;
  letter-spacing: 1px;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: 10px;
}
.faq-search input {
  border-radius: 40px;
  width: 65%;
  height: 50px;
  margin: auto;
  display: block;
  padding-left: 20px;
  border: 2px solid #ddd;
  background: #f5f5f5;
}
.sub-text {
  color: #111;
  text-align: center;
  padding: 15px;
  font-size: 20px;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.faq-content-header {
  font-size: 28px;
  font-weight: normal;
  font-family: Nike TG, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.faq-category {
  font-size: 24px;
  color: #0078b9 !important;
  letter-spacing: 1px;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: calc(100% - 120px);
  display: inline-flex;
  margin-left: 15px;
  text-align: left;
}
.faq-qstn {
  font-weight: bold;
  font-size: 14px;
  padding: 0px 0px 0px 20px;
  display: inline-block;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}
.faq-qstn:hover {
  overflow: visible;
  white-space: normal;
  height: auto;
  text-decoration: underline;
}
.faq-content {
  text-align-last: left;
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 40px;
  padding-bottom: 20px;
  background: #fff;
  border: 2px solid #ddd;
  padding: 20px;
  padding-top: 5px;
}
.gethelp_background {
  border-radius: 3px;
  box-shadow: rgba(0,0,0,0.1) 0px 2px 12px;
  background: #f5f5f5;
  padding: 5px;
  position: absolute;
  overflow: auto;
  max-height: 50%;
  left: 20%;
  min-width: 60%;
  color: #0078b9;
  font-size: 105% !important;
  z-index: 10 !important;
  font-style: italic;
  text-decoration: underline;
}
.faq-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.faq-ans {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
}
.add-position {
  position: fixed;
  right: 7%;
  top: 276px;
}
.faq-add span {
  color: #111 !important;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}
.margin-adjust {
  background: #ddd;
  margin-right: 5px;
  margin-top: 7px;
  padding-right: 1px;
  border-radius: 1px;
}
.cat-option-position {
  float: right;
  width: 70px;
}
.delete-position {
  float: right;
  right: 62px;
  position: relative;
  top: 5px;
}
.add-cat {
  position: relative;
  top: 9.5vh;
  display: block;
  margin-left: 28%;
  margin-right: 25%;
  font-size: 16px;
  background: #ddd;
  color: #fff false;
  padding: 5px;
  border: 2px solid #d5d5d5;
}
.margin-line {
  margin-top: 2px;
  margin-left: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
}
.push-right {
  float: right;
}
.faq-popup-ans {
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: normal;
  color: #0078b9 !important;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-shadow: 3px 1px 3px 1px #b1acac;
  padding: 10px;
  margin-bottom: 30px;
}
.faq-popup-download {
  color: #f00 !important;
  padding-right: 5px;
  padding-left: 5px;
}
.faq-popup-div {
  color: #000;
  cursor: pointer;
  text-align: left;
  padding: 10px;
}
.download-button {
  padding-left: 6px;
}
.faq-popup-qstn {
  font-size: 22px;
  letter-spacing: 0.5px;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-shadow: 3px 1px 3px 1px #b1acac;
  padding: 10px;
  background: #f2f2f2;
}
.faq-popup-qstn-color {
  font-size: 26px;
}
.newQuestionsAns {
  border: solid 1px #dedbd8;
  padding: 5px;
  font-size: 15px;
}
.newQuestionsAns .head {
  font-weight: 700;
  font-size: 15px;
  vertical-align: top;
}
.newQuestionsAns .text {
  font-size: 15px;
  font-weight: 700;
  color: #0758a7;
  vertical-align: top;
}
.qstn-scroll {
  overflow: auto !important;
  height: 100%;
}
.faq-search div {
  font-size: 105% !important;
  z-index: 10 !important;
  background: #fff !important;
  min-width: 60% !important;
  border-radius: 3px !important;
  color: #0078b9 !important;
}
.qstn-option-position {
  float: right;
  background-color: #000;
  margin-left: 8px;
}
.faqModal {
  max-height: 70vh;
  overflow-x: hidden;
  width: 75%;
  background: #e8e8e8;
}
.faq-download-header {
  font-size: 17px;
  float: right;
  font-weight: normal;
  font-family: Nike TG, Helvetica Neue, Helvetica, Arial, sans-serif;
  margin-right: 65px;
  text-decoration: underline;
}
.faq-download-header:hover {
  cursor: pointer;
  color: #0078b9;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-content .div-content {
  color: #000;
  padding: 5px 50px;
  text-decoration: none;
  display: block;
}
.dropdown-content .div-content:hover {
  color: #0078b9;
  cursor: pointer;
  text-decoration: underline;
}
.faq-download-header:hover .dropdown-content {
  display: block;
}
.checkout-form {
  margin: 0 auto;
  width: 75%;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  border: 1px solid #cacfd2;
  color: #000;
  min-height: 450px;
  box-shadow: 5px 5px 5px #b1acac;
}
.collection-height {
  min-height: 58vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.cart-item {
  border-bottom: 1px solid #e0e0e0;
}
.collection .collection-item.desc-card {
  min-height: 84px;
  position: relative;
}
.collection .collection-item {
  display: flex;
  background-color: #fff;
  line-height: 1.5rem;
  padding: 15px 0px;
  margin: 0;
}
.collection-item.desc-card .title {
  font-size: 20px;
  font-weight: bold;
  color: #000;
  display: block;
  margin-bottom: 20px;
}
.collection .collection-item img {
  width: 100px;
  height: 100px;
  margin-right: 25px;
}
.item-desc {
  align-self: center;
  font-weight: bold;
  width: 70%;
}
.item-desc-popup {
  align-self: center;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.cart-header {
  color: #fff;
  background: rgba(0,0,0,0.8);
}
.search-header {
  color: #fff;
  background: rgba(0,0,0,0.8);
  font-size: 1.5rem !important;
}
.align-Item {
  line-height: 100px;
  padding-right: 50px;
}
.align-Item-popup {
  line-height: 100px;
  padding-right: 5px;
}
.collection-item-last {
  border: none;
  font-weight: bold;
}
.full-width {
  width: 40%;
  margin-left: 31%;
}
@media (max-width: 768px) {
  .full-width {
    margin-left: 32%;
  }
}
.full-width-details {
  width: 30%;
}
.cart-dropdown {
  width: 41%;
  height: 32px !important;
  min-height: 32px !important;
  font-weight: bold;
  margin-right: 8%;
}
.cart-dropdow-details {
  padding-left: 2px;
  width: 100%;
  height: 40px !important;
  min-height: 32px !important;
  font-weight: bold;
  margin-right: 3%;
  margin-left: 2px;
  text-align: left;
}
.cart-dropdown {
  width: 35%;
  height: 32px !important;
  min-height: 32px !important;
  font-weight: bold;
  margin-right: 8%;
  margin-left: 2%;
}
.cart-dropdown-details {
  width: 25%;
  height: 32px !important;
  min-height: 32px !important;
  font-weight: bold;
}
.text-cart {
  padding-right: 20%;
}
.popup-area {
  background: #ddd;
  padding-right: 0px;
  padding-bottom: 20px;
  box-shadow: 8px 10px 5px #b1acac;
}
.popup-area-details {
  margin: 10px;
  margin-right: 20px;
  margin-bottom: 30px;
  background: #ddd;
  padding-right: 0px;
  padding-bottom: 20px;
  box-shadow: 8px 10px 5px #b1acac;
  border: 2px solid #ddd;
  padding-top: 0px;
}
.margin-area {
  margin: 10px;
  margin-left: 0px;
  font-size: 14px;
  font-family: "Open Sans Condensed", sans-serif;
  padding-left: 40px;
}
#ex4 .p1[data-count]:after {
  position: absolute;
  right: 10%;
  top: 8%;
  content: attr(data-count);
  font-size: 40%;
  padding: 0.2em;
  border-radius: 50%;
  line-height: 1em;
  color: #fff;
  background: rgba(255,0,0,0.85);
  text-align: center;
  min-width: 1em;
}
.no-data-style {
  font-size: 20px;
  padding: 10px;
  font-weight: bold;
}
.main-calender {
  overflow: visible;
}
.rw-popup-container {
  position: relative !important;
  width: auto !important;
}
.main-datepicker .rw-popup-container {
  position: absolute !important;
  z: 0 !important;
  width: auto !important;
}
.header-color {
  border-bottom: 1px solid #000;
  font-size: 20px;
  font-weight: 700;
  background: rgba(0,0,0,0.8);
  color: #fff;
  padding-left: 5px;
  padding-top: 5px;
  margin-bottom: 20px;
}
.header-color-popup {
  border-bottom: 1px solid #000;
  font-size: 20px;
  font-weight: 700;
  background: rgba(0,0,0,0.8);
  color: #fff;
  padding-left: 5px;
  padding-top: 5px;
  text-align: center;
}
.checkout i {
  float: right;
  padding-right: 5%;
  padding-top: 1.5%;
  color: #ff4500;
  background: #fff;
/* padding: 10px; */
  margin: 10px;
/* text-align: center; */
  padding: 10px;
  border-radius: 5px;
}
.searchicon i {
  float: right;
  padding-right: 5%;
  padding-top: 1.5%;
  color: #ff4500;
  background: #fff;
/* padding: 10px; */
  margin-top: 6px;
/* text-align: center; */
  padding: 10px;
  border-radius: 5px;
}
.noitems i {
  color: #000;
}
.rw-cell,
.rw-btn,
.rw-head-cell {
  font-weight: bold;
}
.cart-3d-checkbox {
  text-align: left;
  padding-left: 15px;
  padding-top: 8px;
  top: 6px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .cart-3d-checkbox {
    top: 13px;
    padding-left: 12px;
  }
}
.cart-3d-checkbox-res {
  text-align: left;
  padding-left: 9px;
  padding-top: 8px;
  top: 12px;
  position: relative;
}
@media (min-width: 769px) and (max-width: 1024px) {
  .full-width {
    width: auto;
    margin-left: 42%;
  }
  .cart-textInp-details {
    width: 100%;
    height: 31px !important;
    min-height: 31px !important;
    margin-right: 0%;
    margin-left: 0px;
    padding: 5px;
    color: #253858;
    font-style: normal;
    font-weight: bold;
  }
  .collection {
    margin: 0 auto;
    width: 100%;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    border: 1px solid #cacfd2;
    color: #000;
    box-shadow: 5px 5px 5px #b1acac;
    max-height: 70vh;
    overflow: scroll;
  }
  .m-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .checkout-class {
    position: unset;
    left: 50%;
  }
  .item-desc-resp {
    align-self: center;
    font-weight: bold;
  }
}
@media (max-width: 1024px) {
  .cart-textInp-details {
    width: 90%;
    height: 31px !important;
    min-height: 31px !important;
    margin-right: 3%;
    margin-left: 0px;
    padding: 5px;
    color: #253858;
    font-style: normal;
    font-weight: bold;
  }
  .margin-cart {
    margin-top: 99px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0px;
  }
  .collection {
    margin: 0 auto;
    width: 100%;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    border: 1px solid #cacfd2;
    color: #000;
    box-shadow: 5px 5px 5px #b1acac;
    max-height: 70vh;
    overflow: scroll;
  }
  .checkout-class {
    position: fixed;
    top: 70vh;
    left: 70%;
  }
}
@media (min-width: 1025px) {
  .cart-textInp-details {
    width: 90%;
    height: 31px !important;
    min-height: 31px !important;
    margin-right: 3%;
    margin-left: 25px;
    padding: 5px;
    color: #253858;
    font-style: normal;
    font-weight: bold;
  }
  .margin-cart {
    margin-top: 99px;
    margin-bottom: 0px;
  }
  .collection {
    margin: 0 auto;
    width: 75%;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    border: 1px solid #cacfd2;
    color: #000;
    box-shadow: 5px 5px 5px #b1acac;
    max-height: 70vh;
    overflow: scroll;
  }
  .checkout-class {
    position: fixed;
    top: 70vh;
    left: 70%;
  }
}
@media (max-width: 767px) {
  .checkout-class {
    position: inherit;
    top: 70vh;
    left: 70%;
  }
}
.cart-popup {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.cart-display {
  display: flex;
  padding: 20px 0px 25px 0px;
  box-shadow: 5px 5px 5px #b1acac;
  border: 1px solid #ddd;
}
@media (max-width: 768px) {
  .table-width {
    width: 92%;
  }
}
@media (max-width: 768px) {
  .table-padding {
    padding-right: 26px;
  }
}
.size-quantity-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-left: 14px;
}
.size-quantity-table-row-header {
  border: 2px solid rgba(0,0,0,0.3);
}
.size-quantity-table-header {
  text-align: left;
  padding: 2px;
  text-align: center;
  background: rgba(0,0,0,0.8);
  color: #fff;
  font-weight: 600;
}
.size-quantity-table-row {
  border: 1px solid #676363;
  text-align: center;
  padding: 4px;
  font-weight: 600;
}
.cart-confirmation-popup {
  position: relative;
  animation-name: addToCart;
  animation-duration: 1s;
  animation-delay: 0s;
}
.show-cart-popup .blur-boxs {
  opacity: 1;
  visibility: visible;
  background: rgba(0,0,0,0.4);
}
.blur-box {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1001;
  opacity: 1;
  visibility: visible;
  background: rgba(0,0,0,0.4);
}
@-moz-keyframes addToCart {
  0% {
    opacity: 0;
    margin-top: -20%;
  }
  50% {
    margin-top: 1%;
  }
  75% {
    margin-top: 2%;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes addToCart {
  0% {
    opacity: 0;
    margin-top: -20%;
  }
  50% {
    margin-top: 1%;
  }
  75% {
    margin-top: 2%;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes addToCart {
  0% {
    opacity: 0;
    margin-top: -20%;
  }
  50% {
    margin-top: 1%;
  }
  75% {
    margin-top: 2%;
  }
  100% {
    opacity: 1;
  }
}
@keyframes addToCart {
  0% {
    opacity: 0;
    margin-top: -20%;
  }
  50% {
    margin-top: 1%;
  }
  75% {
    margin-top: 2%;
  }
  100% {
    opacity: 1;
  }
}
.editor-complete-area {
  height: 500px;
}
.image-editor {
  border: 2px solid rgba(0,0,0,0.8);
}
.editor-area {
  background: #fff;
  color: #000;
  width: 300px !important;
  height: 300px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid rgba(0,0,0,0.8);
}
.upload-image-button {
  display: block !important;
  color: #000;
  margin-top: 20px;
  background: #eaeaea;
}
.editor-upload-button {
  width: 96px;
  overflow: hidden;
}
.editor-upload-area {
  position: relative;
  margin-top: 12px;
  margin-bottom: 12px;
}
.editor-upload-file-name {
  position: absolute;
  top: 0;
  left: 100px;
  font-weight: 600;
}
.editor-slider {
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border: 1px solid rgba(0,0,0,0.8);
}
.editor-divider {
  color: #fff;
}
.editor-slider:hover {
  opacity: 1;
}
.editor-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #d3d3d3;
  border: 1px solid #000;
  cursor: pointer;
}
.editor-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #d3d3d3;
  border: 1px solid #000;
  cursor: pointer;
}
.option-head {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}
.editor-options {
  padding: 3px;
  vertical-align: text-top;
}
.editor-left-icon {
  border-radius: 40%;
  color: #fff;
  background: rgba(0,0,0,0.8);
}
.rotate-left-button {
  width: 100%;
  border: 1px solid rgba(0,0,0,0.8);
}
.rotate-right-button {
  width: 100%;
  border: 1px solid rgba(0,0,0,0.8);
}
.editor-preview {
  border: 2px solid rgba(0,0,0,0.8);
  vertical-align: top;
  height: 95.9%;
  background: #ddd;
}
.editor-preview-heading {
  color: #fff;
  background: rgba(0,0,0,0.8);
  margin: 0 0 6% 0;
  padding: 0;
  font-weight: bold;
  font-size: 24px;
}
.editor-preview-area {
  margin: auto;
  background-color: #f00;
  background-blend-mode: multiply;
  border: 2px solid rgba(0,0,0,0.8);
}
.editor-dropdown-area {
  position: relative;
  margin-top: 12px;
  margin-bottom: 24px;
}
.editor-dropdown {
  width: 100px;
}
.image-type-label {
  position: absolute;
  top: 0;
  font-weight: 600;
}
.image-type-dropdown {
  position: absolute;
  top: 0;
  left: 80px;
  font-weight: 600;
}
.searchbar {
  padding-top: 3px;
  padding-bottom: 57px;
}
.searchbar button {
  width: 12rem;
}
.carousel.carousel-slider .control-arrow {
  top: 40% !important;
  height: 15%;
  color: #fff;
  font-size: 0rem !important;
  border-radius: 50%;
  padding: 0 6% !important;
  background: rgba(0,0,0,0.2) !important;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 !important;
}
.carousel.carousel-slider .control-arrow:hover {
  border: 2px solid rgba(0,0,0,0.4);
  background: rgba(0,0,0,0.3) !important;
}
.popover-button-add-to-cart {
  position: absolute;
  right: 0;
  margin-right: 5.5rem;
  background: transparent;
  color: #ff4500;
}
.react-tiny-popover-container {
  z-index: 100;
}
.popover-add-to-cart {
  border: 2px solid #d3d3d3;
  display: grid;
  padding: 10px;
  margin: 0.2rem 6.2rem;
  width: 80%;
  background-color: #fff;
  box-shadow: -2px -2px 2px #888;
}
.color-add-to-cart {
  background-color: rgba(0,0,0,0);
  color: #fff;
}
.add-to-cart-points {
  list-style-type: square;
}
.add-to-cart-options-heading {
  font-size: 15px;
}
.progress-bar-message {
  text-align: center;
  font-weight: 700;
  left: 48%;
  top: 45%;
  font-size: 1rem;
}
.progress-bar-percentage {
  font-weight: 700;
  font-size: 1.2rem;
  color: #067db8;
  padding-left: 5px;
}
.faq-category-drag-drop {
  font-size: 24px;
  color: #0078b9 !important;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: calc(100% - 120px);
  display: inline-flex;
  margin-left: 15px;
  text-align: left;
}
.faq-sublist-area {
  overflow-y: auto;
}
.faq-sublist-area::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
  background: #bbb;
  border-radius: 6px;
}
.dashboard-search1 input {
  width: 65%;
  font-weight: 400;
  font-size: 16px;
  display: block;
  background: #fff;
  -webkit-box-shadow: 0px 8px 20px rgba(0,0,0,0.349);
  box-shadow: 0px 8px 20px rgba(0,0,0,0.349);
  height: 39px;
}
.dashboard-search input {
  font-weight: 400;
  font-size: 16px;
  display: block;
  background: #f5f5f5 !important;
  height: 48px;
  width: 65%;
  padding: 18px;
  border: 2px solid #ddd;
  border-radius: 40px 0px 0px 40px;
}
.dashboard-search div {
  z-index: 10 !important;
  cursor: pointer;
  font-weight: bold;
  background: #fff !important;
  max-height: 300px !important;
}
.dashboard-search div::-webkit-scrollbar {
  background-image: linear-gradient(to right, #fff 50%, #808080 50%, #fff 51%);
  width: 10px;
}
.dashboard-search div::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 10px;
}
.airbagpage-search input {
  font-weight: 400;
  font-size: 16px;
  display: block;
  height: 50px;
  width: 100%;
  border: 2px solid #ddd;
  border-radius: 40px;
  background: #f5f5f5 !important;
  padding: 18px;
  border: 2px solid #ddd;
  border-radius: 40px 0px 0px 40px;
}
.airbagpage-search div {
  box-sizing: border-box !important;
  z-index: 10 !important;
  cursor: pointer;
  font-weight: bold;
  background: #fff !important;
  max-height: 300px !important;
}
.airbagpage-search div::-webkit-scrollbar {
  background-image: linear-gradient(to right, #fff 50%, #808080 50%, #fff 51%);
  width: 10px;
}
.airbagpage-search div::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 10px;
}
.universal-search-maindiv {
  overflow: visible !important;
  box-sizing: border-box !important;
}
.universal-search input {
  min-height: 40px;
  width: 100%;
  overflow: visible !important;
  font-color: #000;
  border: 2px solid #ddd;
  border-radius: 40px;
  background: #f5f5f5 !important;
  padding: 15px;
  border: 2px solid #ddd;
  border-radius: 40px 0px 0px 40px;
}
.universal-search div {
  z-index: 11 !important;
  cursor: pointer;
  font-weight: bold;
  background: #fff !important;
  max-height: 198px !important;
}
.bg-dropdown {
  background-color: #eee;
}
.bg-dropdown-color {
  background-color: transparent;
}
.display-none {
  display: none !important;
}
.universal-search div::-webkit-scrollbar {
  background-image: linear-gradient(to right, #fff 50%, #808080 50%, #fff 51%);
  width: 10px;
}
.universal-search div::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 10px;
}
.serch-dropdown {
  display: inline-block;
}
.img-wrap {
  float: left;
}
.search-name-desc {
  float: left;
  width: 74% !important;
  margin-left: 8px;
  margin-right: 10px;
  margin-bottom: 17px;
  text-align: justify;
}
.search-name {
  font-size: 15px;
  text-decoration: none;
  font-weight: bold;
  color: #000;
  word-wrap: break-word;
}
.search-desc {
  color: #8a8a8a;
  font-size: 14px;
  font-weight: 600;
}
.search-clear-left {
  clear: left;
}
.render-item {
  display: flex;
  align-items: left;
  margin: 5px;
}
.render-imgStyle {
  vertical-align: middle;
  margin: 10;
  display: flex;
  justify-content: space-between;
}
.loaditem {
  font-size: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  cursor: default;
  color: #8a8a8a;
}
.noitem {
  text-align: center;
  cursor: default;
  font-size: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.search-pop {
  width: 50%;
  top: 40%;
  max-height: 40vh;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .search-pop {
    width: 82%;
    top: 40%;
    max-height: 40vh;
  }
}
@media (max-width: 767px) {
  .search-pop {
    width: 92%;
    top: 40%;
    max-height: 56vh;
  }
  .renderItem-imgStyle {
    display: none;
  }
}
html,
body,
#app-host {
  min-height: 100%;
  background-color: rgba(220,220,220,0.863) DC;
  font-family: 'Open Sans Condensed', sans-serif;
}
.footer {
  position: fixed;
  z-index: 5;
  height: 5rem;
  bottom: 0;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .footer {
    position: relative;
  }
}
.hide-scroll {
  overflow: hidden;
}
.offline-section {
  padding: 1rem;
}
.offline-section h2 {
  text-align: center;
  font-size: 4rem;
  text-transform: uppercase;
}
.text-color-accent {
  color: #0078b9;
}
.u-italic,
em {
  font-style: normal;
  color: #0078b9;
}
.button-top-adjust .u-uppercase {
  float: right;
}
.main-heading {
  font-size: 2.8rem;
  height: 50px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
*:focus {
  outline: none;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .footer {
    position: sticky;
    bottom: 0;
    width: 100%;
  }
}
.not-required-select {
  top: 15px;
  right: 285px !important;
  width: 100px;
  position: fixed;
  z-index: 12;
}
.app-container {
  margin-top: 80px;
  margin-bottom: 100px;
}
.user-center {
  text-align: -webkit-center;
}
.page-align-items {
  margin: 30px;
}
.color-error {
  color: #000;
}
.user,
.usernotfound {
  background: #fff;
  padding-bottom: 50px;
  padding-top: 50px;
  box-shadow: inset 0 1px 0 0 #e5e5e5, inset -1px 0 0 0 #e5e5e5, inset 0 -1px 0 0 #e5e5e5, inset 1px 0 0 0 #e5e5e5;
  border: 3px solid #ddd;
}
.user-404 {
  background: #fff;
  box-shadow: inset 0 1px 0 0 #e5e5e5, inset -1px 0 0 0 #e5e5e5, inset 0 -1px 0 0 #e5e5e5, inset 1px 0 0 0 #e5e5e5;
  border: 3px solid #ddd;
}
.inline-flex-display {
  display: flex;
}
.inline-flex-align {
  display: inline-flex;
}
.admin-grid {
  height: 100%;
}
.select__menu {
  position: relative !important;
  z-index: 150;
}
.sub-heading {
  font-size: 14px;
}
.top-vertical {
  vertical-align: top;
}
.back_color {
  background: #fff;
}
.airbag-type {
  text-transform: uppercase;
}
.ag-theme-balham .ag-row .ag-cell {
  text-transform: uppercase;
}
.ncss-container.fixed-fluid {
  max-width: 87%;
}
.sub-heading-attachment {
  background: #e6e6e6;
  margin: 5px 2px 5px 2px;
  padding: 4px;
  padding-left: 8px;
  width: 80%;
  border: 1px solid #cfcfcf;
  border-radius: 2px;
}
.upload-class {
  float: right;
  background: none;
}
.stl_viewer_div {
  width: 100% !important;
}
.stl_viewer {
  width: 100% !important;
  height: 100% !important;
}
.stl_viewer canvas {
  width: 100% !important;
  height: 100% !important;
}
.m-50 {
  margin: 50px;
  width: 60%;
}
.mt-10 {
  margin-top: 10px;
}
.ml-32 {
  margin-left: 32px;
}
.icon-warn i {
  font-size: 60px;
}
.error-head {
  color: #e34843;
  font-size: 48px;
  font-weight: 900;
  margin: 20px 0px;
}
.error-title {
  color: #5e5e5e;
  font-size: 32px;
  font-weight: 600;
  margin: 30px 0px;
}
.error-desc {
  word-wrap: break-word;
  color: #777;
  font-size: 24px;
  line-height: 1.5;
}
