.invalid-res
    color: #000000;
    text-align: center;
    font-size: 34px;
    padding-left: 20px;

.invalid-message
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: left;
    padding-left: 3px;
