@require 'variables'

.dashboard
    margin: auto;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
.dashboard h1
     @media screen and (max-height 1000px)
       font-size: 1.8rem
.dashboard H4
     @media screen and (max-height 1000px)
       font-size: .9rem
       margin:12px 0px 12px 0px       
.card-title
    z-index: 4;
    position: absolute;
    font-weight: 700;
    font-size: 3.5rem;
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    color: rgba(0,0,0,.75);
    -webkit-transition: all .4s ease-out;
    transition: all .4s ease-out;
    text-align: center
    @media screen and (max-width 767px)
        font-size: 2.5rem
        color: rgba(0,0,0,.75);
        transition: all .4s ease-out
    @media screen and (min-width 768px) and (max-width 916px)
        font-size: 2.5rem
        color: rgba(0,0,0,.75);
        transition: all .4s ease-out
    @media screen and (min-width 916px) and (max-width 1023px)
         font-size: 3rem
         color: rgba(0,0,0,.75);
         transition: all .4s ease-out
    @media screen and (min-width 1024px) and (max-width 1279px)
         font-size: 3.5rem
         color: rgba(0,0,0,.75);
         transition: all .4s ease-out
    @media screen and (min-width 1280px) and (max-width 1365px)
         font-size: 3rem
         color: rgba(0,0,0,.75);
         transition: all .4s ease-out
    @media screen and (min-width 1366px) and (max-width 1679px)
         font-size: 3rem
         color: rgba(0,0,0,.75);
         transition: all .4s ease-out
    @media screen and (min-width 1680px) and (max-width 1900px)
         font-size: 3.5rem
         color: rgba(0,0,0,.75);
         transition: all .4s ease-out
.view
    float right
    top 0
    left 0
    height 15rem
    .card-img-top
        width 100% !important
        height 100% !important
        position: absolute
        object-fit: contain
        filter: blur(2px) opacity(70%)
        transition: all .4s ease-in-out
        @media screen and (max-height 600px)
            width 70% !important
            height 70% !important
         @media screen and (max-height 700px)
            width 80% !important
            height 80% !important
         @media screen and (max-height 800px)
            width 80% !important
            height 80% !important
        @media screen and (max-height 1000px)
            width 100% !important
            height 100% !important
    .blur
        display: none
        transition: all .4s ease-in-out
        @media screen and (max-height 600px)
            width 70% !important
            height 70% !important
         @media screen and (max-height 700px)
            width 80% !important
            height 80% !important
         @media screen and (max-height 800px)
            width 90% !important
            height 80% !important
        @media screen and (max-height 1000px)
            width 100% !important
            height 100% !important

.view:hover
    .card-img-top
        filter: blur(0px)
        transition: all .4s ease-in-out
        position: absolute
        clip: rect(98px,500px,500px,0px);
        @media screen and (max-height 1000px)
            clip: rect(85px,500px,500px,0px);
    .blur
        transition: all .4s ease-in-out
        display: flex
        position: absolute
        overflow: hidden
        height: 100%
        width: 100%
        clip: rect(0px,500px,100px,0px);
        filter: blur(2px) opacity(50%)
    .card-title
        transition: all .4s ease-in-out
        transform: translateY(-1rem);
        color: rgba(0,0,0,.80);

.card
    width:100%
    border 1px solid #CACFD2
    font-weight: bold;
    color: black
    box-shadow: 5px 5px 5px #b1acac;
    position: relative
    overflow: hidden
    margin-bottom: 0
    @media screen and (min-height 500px) and (max-height 700px)
        width:86% 
        height calc(100vh - 79vh)
        margin-right: 5%
        margin-left: 10%
    @media screen and (min-height 701px) and (max-height 1600px)
        width:90% 
        height calc(100vh - 79vh)
        margin-right: 5%
        margin-left: 10%
.dashboard p
    font-weight: normal

    /* Detailed Card */
.details-card
    position: relative;
    width: 100%;
    height: 400px;
    border: 1px solid #cacfd2;
    font-weight: bold;
    color: #000;
    box-shadow: 5px 5px 5px #b1acac;
    overflow: hidden;
    margin-bottom: 15px;
    margin-top: 40px;

.airbag-img-carousel .carousel .slide
    background-color : #fff

.airbag-img-carousel
    padding: 14px 10px 0;

.image-carousel .carousel .slide
    background-color : #fff

.airbag-img
    width 100% !important
    height auto !important
    position: absolute
    padding: 30px 20px 0px 20px;
    top: 51px

.card-list
   width:100%  
   

.card-list td
    padding: 5px 0px 5px 1rem;
    font-size: 14px;
    color: #000;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    text-transform: capitalize;
    table-layout: fixed; 
    width:50%;

.listcard-items
    width:50%

.bag-filter-card
    background: #ddd;
    font-size 14px
    font-weight: bold

.card-space-adjust
    margin-bottom: 20px

.tooltip-img
    width 100% !important
    height auto !important
    border: 1px solid #34495E  
    position: absolute

.tool-tip-info
    position absolute
    top 0
    left 52%

.tool-tip-container
    position relative

.__react_component_tooltip 
    width 500px !important
    height 300px !important
    box-shadow: 5px 5px 5px #b1acac;

.tooltip-heading
    font-size: 2rem
    font-weight: 900

.tooltip-type
    font-size: 1.5rem
    font-weight: 900
    color ncss-grey
    -webkit-text-fill-color: ncss-grey;
    -webkit-text-stroke-width: .1px;
    -webkit-text-stroke-color: black;

.tooltip-size
    font-size: 1.5rem    
    font-weight: 600

.tooltip-listcard
    min-width: 50px!important;
    max-width: 200px!important;
    margin-top: 1px !important;
    height: auto!important; 
    word-break: break-all
    overflow-wrap: break-word
    display: block

.size
    background-color #2C3E50
    color white
    height 30px
    width 100%
    border-radius 20px
    font-size 17px !important
    font-weight 600
    padding-left: 15px
  

.airbag-heading
    font-size: 28px;
    color: #fff;
    opacity: 1 !important;
    width: 100%;
    background: rgba(0,0,0,.8 );
    padding: 10px; 
    z-index :10

.airbag-size
    float right
    opacity: 1 !important 

.airbag-price
    font-size: 15px

.airbag-fabheight
    float: right
    opacity: 1 !important
    word-wrap: break-word
    max-width: 75%
    min-width: unset
    font-size: 15px

.airbag-details
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0,0,0,0.8);
    color: white;
    font-size: 17px;
    padding: 2px;




    /*...................Modal Styles.........................*/
.separator
	margin-top : 5px
	border : 1px solid #C3C3C3

.modal-button
    padding: .6rem 1.2rem
    position: absolute
    background: rgba(0,0,0,.6)
    width 75%
    color white  
    top: 30%
    left: 50%
    transform: translate(-50%, -50%)
    text-transform: uppercase
    font-weight: 700
    display none

.modal-buttons
    background-color: #808080;
    border: none;
    color: white;
    padding: 5px;
    text-align: center;
    display: inline-block;
    font-size: 13px;
    float: right;
    font-weight: bold;
    font-family: 'Open Sans Condensed', sans-serif;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    margin-top: -11px

.modal-button:hover
    background: rgba(0,0,0,1);
    color: #ffffff;
    .modal-area:hover
        background: transparent;

.modal-area
    widows 100%
    height 100%
    background: white;
.modal-area:hover
    background: rgba(242,242,242, 0.5)
    .modal-button
        display inline
        border-radius: 3px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease 0s;

    .modal-img
        opacity 0.5

.airbag-link
    height 100%
.airbag-modal-container
    position relative
    padding-bottom: 25px
    height:70vh
    
.airbag-modal-img
    border: 1px solid rgba(225,225,225,0.3);
    box-shadow: 8px 10px 5px #b1acac;
    /* min-height: 30vh; */
    min-width: 34.7vw;
    max-height: 60vh;
    padding:10vh;

.airbag-modal-carousal
    margin-top: 60px;
    margin-left: 30px;

.airbag-modal-img-cart
    border: 1px solid rgba(225,225,225,0.3);
    box-shadow: 8px 10px 5px #b1acac;
    
.airbag-modal-info
    position absolute
    top 0 
    left 50%  
    width: 47% 

.airbag-modal-heading
    left: 60%;
    font-size: 1.7rem;
    font-weight: 900;
    background: rgba(0,0,0,.8);
    color: white;
    box-shadow: 8px 10px 5px #b1acac;

.airbag-modal-type
    font-size: 1.5rem;
    font-weight: 900;
    color: #fff;
    background: rgba(0,0,0,.8);
    padding-bottom: 4px;
    box-shadow: 8px 10px 5px #b1acac;
    text-transform: uppercase;

.airbag-modal-size
    font-size: 1.5rem    
    font-weight: 600

.details-view
    text-align: left
    margin-top: 10px
    margin-left 10px

.details-heading, .details-description
    font-size: 1.2rem
    font-weight: 600
   
    .details-heading
        width: 40%

.details-description
    float: right
    width: 60%
    word-break: break-word;
    @media screen and (max-width: 768px)
        width: 50%;

.background-details-modal
    background:#ddd;
    box-shadow: 8px 10px 5px #b1acac;
    padding: 20px 10px 30px 10px
    overflow: auto

.details-description-button
    font-size: 15px
    margin-top: 80%
    float: left;
    padding: 5px
    color: #ffffff
    background :rgba(0,0,0,.8)
    padding-left: 15px;
    padding-right: 15px;

.text-button-modal
    font-family: "Open Sans Condensed", sans-serif;
    margin-left: 5px

.size-background
    background: rgba(0,0,0,.8);
    padding: 2px;
    margin: 2px;
    color: #ffffff
    padding-left: 5px;
    padding-right: 5px;
    white-space: nowrap
    font-size: 11px;
    text-align: center;
.not-available
    padding-left: 5px;
    font-size: 1.2rem;
    text-align: center;
.details-heading-size
    font-size: 1.2rem
    font-weight: 600
    overflow-x: hidden;
    text-overflow: ellipsis;

download-button
    padding-left: 6px

.fa.fa-download:hover 
    color: rgba(0,0,0,.8);
    cursor: pointer;
    font-size:1.5em;

.test-img-class
    height auto !important
    width: 100%    !important

.collectionalert
    margin: 0 auto;
    width: 65%;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    border: 4px solid #cacfd2;
    color: #000;
    box-shadow: 5px 5px 5px #b1acac;
    background: #dddddd;
    margin-bottom: 10px;


.collectionalert .collection-item.desc-card 
    min-height: 84px;
    position: relative;

.collectionalert .collection-item
    display: flex;
    background-color: #fff;
    // line-height: 1.5rem;
    padding: 15px 0px;
    margin: 0;
    

.collectionalert .collection-item img
    width: 80px;
    height: 80px;
    margin-right: 25px;

.align-Items
    padding-right: 20px

.modal-check-btn
    background transparent
    float left
    font-size: 35px


.margin-extra
    margin-top: 35px;
    margin-left: 15px;

@media (max-width: 1024px)
    .airbag-modal-info
        position: absolute;
        width: 100%;
        left: 0%;
        top: auto;
        padding-bottom:36px;
    .margin-extra
        margin-top: 15px;
        margin-left: 15px;      