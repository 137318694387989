.faq-search-section{
    background-color: #ffffff;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
    border: 2px solid #ddd;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20px;
}
.header-text{
    color: #0078b9 !important;
    font-size: 40px;
    text-align: center;
    letter-spacing: 1px;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: 10px;
}
.faq-search{
    input{
        border-radius: 40px;
        width: 65%;
        height: 50px;
        margin: auto;
        display: block;
        padding-left: 20px;
        border: 2px solid #ddd;
        background: #F5F5F5;
    }
}
.sub-text{
    color: #111;
    text-align: center;
    padding: 15px;
    font-size: 20px;
    font-family: "Nike TG","Helvetica Neue",Helvetica,Arial,sans-serif;
}
.faq-content-header{
    font-size: 28px;
    font-weight: normal;
    font-family: Nike TG,Helvetica Neue,Helvetica,Arial,sans-serif;
}
.faq-category{
    font-size: 24px;
    color: #0078b9 !important;
    letter-spacing: 1px;
    font-family: "Nike TG","Helvetica Neue",Helvetica,Arial,sans-serif;
    // padding: 10px 20px 0px 20px;
    width: calc(100% - 120px);
    display: inline-flex;
    margin-left: 15px;
    text-align: left;
}
.faq-qstn{
    font-weight: bold;
    font-size: 14px;
    padding: 0px 0px 0px 20px;
    display: inline-block;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
}
.faq-qstn:hover{
    overflow: visible; 
    white-space: normal;
    height: auto; 
    text-decoration: underline;
}
.faq-content{
    text-align-last: left;
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 40px;
    padding-bottom: 20px;
    background: #ffffff;
    border: 2px solid #ddd;
    padding: 20px;
    padding-top: 5px;
    

}
.gethelp_background{
     border-radius: 3px;
     box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
     background:#f5f5f5;
     padding: 5px;
     position: absolute;
     overflow: auto;
     max-height: 50%;
     left: 20%;
     min-width: 60%;
     color #0078b9
     font-size: 105% !important;
     z-index: 10 !important;
     font-style: italic;
     text-decoration: underline
}
.faq-padding{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.faq-ans{
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
}
.add-position{
    position: fixed;
    right: 7%;
    top: 276px;
}
.faq-add{
    span{
        color: #111111!important;
        font-family: "Nike TG","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 14px;
    }
}
.margin-adjust{
    background: #ddd;
    margin-right: 5px;
    margin-top: 7px;
    padding-right: 1px;
    border-radius: 1px;

}
.cat-option-position{
    float: right;
    width: 70px;
}
.delete-position{
    float: right;
    right: 62px;
    position: relative;
    top: 5px;
}
.add-cat{
    position: relative;
    top: 9.5vh;
    display: block;
    margin-left: 28%;
    margin-right: 25%;
    font-size: 16px;
    background: #ddd;
    color: #fff false;
    padding: 5px;
    border: 2px solid #d5d5d5;
}
.margin-line{
    margin-top: 2px;
    margin-left: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    }
.push-right
    float:right
.faq-popup-ans{
    font-size: 18px;
    letter-spacing: .5px;
    font-weight: normal;
    color: #0078b9 !important;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
    box-shadow: 3px 1px 3px 1px #b1acac;
    padding: 10px;
    margin-bottom: 30px;
}  
.faq-popup-download
    color: red !important;
    padding-right: 5px
    padding-left: 5px
    
.faq-popup-div
    color: #000;
    cursor: pointer;
    text-align: left;
    padding: 10px;
    //background: rgba(0,0,0,.1);

.download-button
    padding-left: 6px

.faq-popup-qstn{
    font-size: 22px;
    letter-spacing: .5px;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
    box-shadow: 3px 1px 3px 1px #b1acac;
    padding: 10px
    background: #f2f2f2;
}  
.faq-popup-qstn-color{
    font-size: 26px;
}
.newQuestionsAns{
    border: solid 1px #dedbd8;
    padding: 5px;
    font-size: 15px;
}
.newQuestionsAns .head
    font-weight: 700;
    font-size: 15px;
    vertical-align: top;
.newQuestionsAns .text
    font-size: 15px;
    font-weight: 700;
    color: #0758a7; 
    vertical-align: top;
.qstn-scroll
    overflow: auto !important;
    height: 100%;

.faq-search div
    font-size: 105% !important
    z-index: 10 !important
    background: #fff !important
    //top: 28% !important
    min-width: 60% !important
    border-radius: 3px !important
    color:#0078b9 !important

.qstn-option-position
    float: right;
    background-color: #000;
    margin-left: 8px; 

.faqModal
    max-height 70vh;
    overflow-x: hidden;
    width: 75%;
    background: #e8e8e8;

.faq-download-header
	    font-size: 17px;
	    float: right
	    font-weight: normal;
	    font-family: Nike TG,Helvetica Neue,Helvetica,Arial,sans-serif;
	    margin-right: 65px;
	    text-decoration: underline;
	
	.faq-download-header:hover 
	    cursor: pointer; 
	    color: #0078b9;  
	
	.dropdown-content
	  display: none;
	  position: absolute;
	  background-color: #f1f1f1;
	  min-width: 160px;
	  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	  z-index: 1;
	
	
	.dropdown-content .div-content
	  color: black;
	  padding: 5px 50px;
	  text-decoration: none;
	  display: block;
	
	
	.dropdown-content .div-content:hover
	    color: #0078b9;
	    cursor: pointer; 
	    text-decoration: underline;
	
	.faq-download-header:hover .dropdown-content {display: block;}