.checkout-form
    margin: 0 auto;
    width: 75%;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    border: 1px solid #cacfd2;
    color: #000;
    min-height: 450px;
    box-shadow: 5px 5px 5px #b1acac;

.collection-height
    min-height: 58vh;
    overflow-y: auto
    overflow-x: hidden
    
.cart-item
    border-bottom: 1px solid #e0e0e0; 

.collection .collection-item.desc-card 
    min-height: 84px;
    position: relative;

.collection .collection-item
    display: flex;
    background-color: #fff;
    line-height: 1.5rem;
    padding: 15px 0px;
    margin: 0;

.collection-item.desc-card .title 
    font-size: 20px;
    font-weight: bold
    color: #000;
    display: block;
    margin-bottom: 20px;
    

.collection .collection-item img
    width: 100px;
    height: 100px;
    margin-right: 25px;

.item-desc
    align-self:center;
    font-weight: bold
    width 70%

.item-desc-popup
    align-self:center;
    font-weight: bold
    font-size: 20px;
    display: flex;
    justify-content: center;
    width: 100%

.cart-header
    color: #fff;
    background: rgba(0,0,0,0.8);

.search-header
    color: #fff;
    background: rgba(0,0,0,0.8);
    font-size: 1.5rem !important

.align-Item
    line-height: 100px
    padding-right: 50px
   
 .align-Item-popup
    line-height: 100px    
    padding-right: 5px
         

.collection-item-last
    border: none
    font-weight: bold

.full-width
    width:40%
    margin-left: 31%;
    @media (max-width: 768px)
        margin-left: 32%; 

.full-width-details
    width:30%
   

.cart-dropdown 
    width: 41%;
    height: 32px !important;
    min-height: 32px !important;
    font-weight: bold;
    margin-right: 8%;

.cart-dropdow-details 
    padding-left: 2px;
    width: 100%;
    height: 40px !important;
    min-height: 32px !important;
    font-weight: bold;
    margin-right: 3%;
    margin-left: 2px;
    text-align: left;

.cart-dropdown 
    width: 35%;
    height: 32px !important;
    min-height: 32px !important;
    font-weight: bold;
    margin-right: 8%;
    margin-left:2%

.cart-dropdown-details 
    width: 25%;
    height: 32px !important;
    min-height: 32px !important;
    font-weight: bold;

.text-cart
    padding-right: 20%;

.popup-area
    background: #dddddd;
    //padding-left: 40px;
    padding-right: 0px;
    padding-bottom: 20px;
    box-shadow: 8px 10px 5px #b1acac;

.popup-area-details
    margin: 10px;
    margin-right: 20px;
    margin-bottom: 30px;
    background: #dddddd;
    padding-right: 0px;
    padding-bottom: 20px;
    box-shadow: 8px 10px 5px #b1acac;
    border: 2px solid #dddddd;
    padding-top:0px;

.margin-area
    margin: 10px;
    margin-left: 0px;
    font-size: 14px;
    font-family: "Open Sans Condensed", sans-serif;
    padding-left: 40px;

#ex4 .p1[data-count]:after{
  position:absolute;
  right:10%;
  top:8%;
  content: attr(data-count);
  font-size:40%;
  padding:.2em;
  border-radius:50%;
  line-height:1em;
  color: white;
  background:rgba(255,0,0,.85);
  text-align:center;
  min-width: 1em;
  //font-weight:bold;
}

.no-data-style
    font-size: 20px;
    padding: 10px;
    font-weight: bold;

.main-calender
    overflow: visible

.rw-popup-container 
    position: relative !important ;
    width: auto !important;

.main-datepicker .rw-popup-container 
    position: absolute!important;
    z: 0!important;
    width: auto!important;


.header-color
    border-bottom: 1px solid #000;
    font-size: 20px;
    font-weight: 700;
    background: rgba(0,0,0,.8);
    color: #fff;
    padding-left: 5px;
    padding-top: 5px;
    margin-bottom: 20px;

.header-color-popup
    border-bottom: 1px solid #000;
    font-size: 20px;
    font-weight: 700;
    background: rgba(0,0,0,.8);
    color: #fff;
    padding-left: 5px;
    padding-top: 5px;
    text-align: center;

.checkout i
    float: right;
    padding-right: 5%;
    padding-top: 1.5%;
    color: #ff4500;
    background: white;
    /* padding: 10px; */
    margin: 10px;
    /* text-align: center; */
    padding: 10px;
    border-radius: 5px;

.searchicon i
    float: right;
    padding-right: 5%;
    padding-top: 1.5%;
    color: #ff4500;
    background: white;
    /* padding: 10px; */
    margin-top: 6px;
    /* text-align: center; */
    padding: 10px;
    border-radius: 5px;

.noitems i
    color: #000000;

.rw-cell, .rw-btn, .rw-head-cell
    font-weight: bold;

.cart-3d-checkbox
    text-align: left;
    padding-left: 15px;
    padding-top: 8px;
    top: 6px;
    position: relative;
    @media screen and (max-width: 768px) 
        top: 13px;
        padding-left: 12px;

.cart-3d-checkbox-res
    text-align: left;
    padding-left: 9px;
    padding-top: 8px;
    top: 12px;
    position: relative;
        
@media (min-width: 769px) and (max-width: 1024px) 
    .full-width
        width:auto
        margin-left: 42%;
    .cart-textInp-details 
        width: 100%;
        height: 31px !important;
        min-height: 31px !important;
        margin-right: 0%;
        margin-left: 0px;
        padding: 5px;
        color: #253858;
        font-style: normal;
        font-weight: bold;        
    .collection 
        margin: 0 auto;
        width: 100%;
        border-radius: 2px;
        overflow: hidden;
        position: relative;
        border: 1px solid #cacfd2;
        color: #000;
        box-shadow: 5px 5px 5px #b1acac;
        max-height: 70vh;
        overflow: scroll;  
    .m-0 
        margin-left 0px;
        margin-right 0px;
    .checkout-class
        position: unset;
        left: 50%;   
    .item-desc-resp
        align-self:center;
        font-weight: bold

@media (max-width: 1024px)
    .cart-textInp-details 
        width: 90%;
        height: 31px !important;
        min-height: 31px !important;
        margin-right: 3%;
        margin-left: 0px;
        padding: 5px;
        color: #253858;
        font-style: normal;
        font-weight: bold;
    .margin-cart
        margin-top: 99px
        margin-left:10px
        margin-right:10px
        margin-bottom: 0px;
    .collection 
        margin: 0 auto;
        width: 100%;
        border-radius: 2px;
        overflow: hidden;
        position: relative;
        border: 1px solid #cacfd2;
        color: #000;
        box-shadow: 5px 5px 5px #b1acac;
        max-height: 70vh;
        overflow: scroll;
    .checkout-class
        position: fixed;
        top: 70vh;
        left: 70%;

@media (min-width: 1025px)
    .cart-textInp-details 
        width: 90%;
        height: 31px !important;
        min-height: 31px !important;
        margin-right: 3%;
        margin-left: 25px;
        padding: 5px;
        color: #253858;
        font-style: normal;
        font-weight: bold;
    .margin-cart
        margin-top: 99px
        margin-bottom: 0px;
    .collection 
        margin: 0 auto;
        width: 75%;
        border-radius: 2px;
        overflow: hidden;
        position: relative;
        border: 1px solid #cacfd2;
        color: #000;
        box-shadow: 5px 5px 5px #b1acac;
        max-height: 70vh;
        overflow: scroll;
    .checkout-class
        position: fixed;
        top: 70vh;
        left: 70%;

.checkout-class
    @media (max-width: 767px)
        position: inherit
        top: 70vh;
        left: 70%;
    
.cart-popup
    display: flex 
    flex-direction: column
    padding-top: 30px
    
.cart-display
    display:flex
    padding: 20px 0px 25px 0px
    box-shadow: 5px 5px 5px #b1acac;
    border: 1px solid #dddddd;

.table-width
    @media (max-width: 768px)
        width: 92%; 

.table-padding
    @media (max-width: 768px)
        padding-right: 26px 
