.checkout i {
  float: right;
  padding-right: 5%;
  padding-top: 1.5%;
  color: #ff4500;
  background: #fff;
/* padding: 10px; */
  margin: 10px;
/* text-align: center; */
  padding: 10px;
  border-radius: 5px;
}
.noitems i {
  color: #000;
}
@media screen and (max-width: 767px) {
  .checkout-view {
    display: flex;
    flex-direction: column;
  }
  .checkout-width {
    width: 100%;
  }
}
