nav-font-color = #fff
menu-font-color = #000
nav-height = 68px

nav-logo-sm-size = 30px
nav-logo-lg-size = 22px

nav-right-margin = 0

.nav-header-wrapper
  width 100%
  background nav-bg-color
  color nav-font-color
  height nav-height
  box-shadow box-shadow-divider

.nav-header-container
  display flex
  width 100%
  a
    color nav-font-color


.nav-header-title
  flex 1 1 auto
  padding-top 8px
  span
   
    font-size 2rem
    @media screen and (min-width 641px)
      margin-left 70px
  i
    //So we don't mess with the line height
    //of the rest of the nav bar with the negative margin
    position absolute
    font-size nav-logo-lg-size
    margin-top 12px



.nav-header-block
  flex 0 0 auto

  &:last-child
    margin-right nav-right-margin

  .nav-header-menu li
    height nav-height
    margin-right 1rem
    display: inline-block;

    a, span
      padding 0 .5rem
      font-size 1.3rem
      font-weight 700
      vertical-align middle
      line-height nav-height
      display: inline-block;
      height: nav-height

      &.active
        border-bottom 3px solid ncss-accent
        color ncss-accent
