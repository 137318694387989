.popover-button-add-to-cart
    position: absolute;
    right: 0;
    margin-right: 5.5rem;
    background: transparent;
    color:#FF4500;

.react-tiny-popover-container
    z-index: 100

.popover-add-to-cart
    border: 2px solid lightgray;
    display: grid;
    padding:10px
    margin: 0.2rem 6.2rem;
    width: 80%;
    background-color: white
    box-shadow: -2px -2px 2px #888888;   
   
.color-add-to-cart
    background-color: rgba(0,0,0,0)
    color : white

.add-to-cart-points 
  list-style-type: square;


.add-to-cart-options-heading
    font-size: 15px