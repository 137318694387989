@require 'variables'

.footer-container
    background-color nav-bg-color
    color ncss-white
    padding 0.7rem 0
    a
        color ncss-white

.footer-copyright
    display block
    float right

.footer-margin-adjust
    margin-top: 0px
    margin-bottom: 0px

.vertical-line
    border-left 3px  solid ncss-white
    margin: 15px
      
.footer-hr
    border-color ncss-grey
    
.page-align-items
   flex 1 0 auto
   min-height 60vh
   margin 0 30px 0 30px
   @media screen and (max-width: 990px)
      margin 44px 20px 60px 20px
   @media screen and (max-width: 640px)
      margin 44px 00px 00px 00px

.page-align-fixed-items 
     overflow: hidden;
     padding: 0px 0px 0px 0px !important  
