.season-select-component
    border: 1px solid #ddd  
    border-radius: 4px; 

.season-select-results
    border: 1px solid #ddd;
    min-height: 45px;
    padding: 5px;
    background: #ffffff;
    font-size: 16px;

.season-placeholder
    color hsl(0,0%,50%)     