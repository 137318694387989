.faq-category-drag-drop{
    font-size: 24px;
    color: #0078b9 !important;
    font-family: "Nike TG","Helvetica Neue",Helvetica,Arial,sans-serif;
    // padding: 10px 20px 0px 20px;
    width: calc(100% - 120px);
    display: inline-flex;
    margin-left: 15px;
    text-align: left;
}
.faq-sublist-area{
    overflow-y: auto
}
// .faq-sublist-area::-webkit-scrollbar-thumb {
//     width: 12px; 
//     border-radius: 6px;
//     background-color: rgba(0,0,0,.3);
//     box-shadow: 0 0 1px rgba(255,255,255,.5);
// }
.faq-sublist-area::-webkit-scrollbar {
   width: 6px;               /* width of the entire scrollbar */
   background: #bbbbbb
   border-radius: 6px;
}
