@require 'variables'

.dashboard

    .status-area
        vertical-align: top;
        padding: 2rem 0 0 0;

    .status-box
        padding: 0.5rem 0;

    .chart-area
       border-left: 1px solid ncss-grey;
       padding: 0 5rem;

    .p0-top-sm
        padding: 0 1px 1px 1px

    .m0-sm
        margin 0   

    .lab
        margin-left: -36px
        margin-right: -34px

.dashboard-button
   display inline-block
   @media screen and (max-width: 640px)
      display inline-block
      width 21%
      margin 0 2%
      padding 5px 10px


.comment-container
   height 100%
   padding 0

.comment-section
   height calc( 100vh - 235px)
   overflow auto
   width 100%

.log-section
   height calc( 100vh - 100px)
   overflow auto
   width 100%

.comment-box 
   width 100%
   position relative
   margin-bottom 12px
   display block

.comment-head
   background #E5E5E5
   padding 0px 10px;
   overflow hidden
   border-radius 4px 4px 0 0

.comment-content
   background #FAFAFA
   padding  2px 10px
   border 1px solid #E5E5E5
   font-size 15px
   color #595959
   border-radius 0 0 4px 4px

.comment-foot
   background #E5E5E5
   padding 0px;
   overflow hidden
   border-radius  0 0 4px 4px
   display block
   min-height 30px
   padding-left 10px

.textareaComment
    min-height 60px
    border 1px solid #E5E5E5
    background #fefefe
    box-shadow none
    display block
    width 100%

.comment-add-button
    display block
    float right
    background-color #000
    border none
    color #fff;
    border-radius 0 0 5px 0
    padding 5px 10px


.score-card-divider
   width: 20%
   border-left 1px solid #dedede
   @media screen and (max-width: 640px) 
      border-top 1px solid #dedede

.score-card-divider:nth-child(1),
.score-card-divider:nth-child(2)  
   border-top none !important   


.score-card-divider:nth-child(1)
   border-left none !important  

.score-card-divider:nth-child(odd)
   @media screen and (max-width: 640px)
      border-right 1px solid #dedede
      border-left none  
   @media screen and (min-width: 640px) 
      border-left 1px solid #dedede

.border-solid-light-grey
   border 1px solid #efefef

.ncss-btn-primary-dark 
   padding: 5px 24px;
   border-radius: 20px;
   background: #111;
   color: #fff;

.text-color-error
   color: #fa5400;
   font-weight: normal;
   font-size: smaller;

.header-brand
   padding-left: 85px !important;

.search-Button
    position: absolute;
    bottom: 11px;
    background: #fff;
    right: 787px;
    font-size: 20px;
    color: #e7e7e9;
    cursor: default;

.search-container
   margin-left: 18%
