@require 'variables'

.markdown-container

  ul
    list-style disc inside
  ol
    list-style decimal inside

  ul ul, ol ul
    list-style-type circle
    list-style-position inside
    margin-left 15px

  ol ol, ul ol
    list-style-type lower-latin
    list-style-position inside
    margin-left 15px

  a
    color ncss-link-color
    text-decoration underline

  h1,h2,h3,h4,h5
    margin-top 2rem
    margin-bottom 1rem
  h4
    font-size 22px
  h5
    font-size 18px
  img
    margin .5rem 0
  code
    background-color: ncss-offwhite
    color #D04670
    padding 2px 4px

  pre code
    background-color: ncss-offwhite
    display: inline-block;

.tooltip-markdown 
  a
    color ncss-white
    text-decoration underline
  .markdown-container
    code
      background-color: #888
      color ncss-white
      font-family: Consolas, monaco, monospace