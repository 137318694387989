  .confirm-modal-overlay
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background-color rgba(0,0,0,0.5)
    z-index: 13

  .confirm-modal-close-btn
    background transparent
    margin 5px -15px 0 0
    float right

  .confirm-close
    margin-bottom: -7px;

  .confirm-modal-text
    font-size 18px
    color #000000
    font-weight bold
    padding-bottom: 50px
    @media (max-width: 767px)
      text-align: justify;
      height: 45vh;  
  
.bottom-section
    text-align: center

.popup-bottom 
    float: right

.button-width-adjust 
    width: 8rem;
    padding: .4rem 2.5rem;
    margin-right: 10px;
    color: #fff;
    @media screen and (max-width: 767px)
      width: 6.6rem;
      padding: 7px

@media (max-width: 1024px) 
  .confirm-modal-container
      position absolute
      top 55%
      left 50%
      right auto
      bottom auto
      background-color #ffffff
      margin 0 auto
      transform translate(-50%, -50%)
      padding 10px 20px
      width 70%
      border 5px solid rgba(0,0,0,0.8) !important;

@media (min-width: 1025px)
    .confirm-modal-container
      position absolute
      top 40%
      left 50%
      right auto
      bottom auto
      background-color #ffffff
      margin 0 auto
      transform translate(-50%, -50%)
      padding 10px 20px
      width 40%
      border 5px solid rgba(0,0,0,0.8) !important;

.confirm-display
    @media (max-width: 767px)
      display: flex
      padding-bottom: 10px

.confirm-modal-height
  @media (max-width: 767px)
    height: 40vh
