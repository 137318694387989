@require 'variables'

box-shadow-size = 0 2px 5px 0 rgba(0, 0, 0, 0.2)
padding-base = 12px
success-color = alpha(ncss-success, 0.9)
danger-color = alpha(ncss-error, 0.8)
text-color = rgba(0, 0, 0, 0.8)
border-radius = 1px
.alertify
	position fixed
	background-color rgba(0, 0, 0, 0.3)
	left 0
	right 0
	top 0
	bottom 0
	width 100%
	height 100%
	z-index 99999
	&.hide
		opacity 0
		pointer-events none
	&, &.show
		box-sizing border-box
		transition all 0.33s cubic-bezier(0.25, 0.8, 0.25, 1)
	&, *
		box-sizing border-box
	.dialog
		padding 12px
	.dialog, .alert
		width 100%
		margin 0 auto
		position relative
		top 50%
		transform translateY(-50%)
		> *
			width 400px
			max-width 95%
			margin 0 auto
			text-align center
			padding padding-base
			background #fff
			box-shadow 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084)
		.msg
			padding padding-base
			margin-bottom padding-base
			margin 0
			text-align left
		input:not(.form-control)
			margin-bottom 15px
			width 100%
			font-size 100%
			padding padding-base
			&:focus
				outline-offset -2px
		nav
			text-align right
			button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button)
				background transparent
				box-sizing border-box
				color rgba(0, 0, 0, 0.87)
				position relative
				outline 0
				border 0
				display inline-block
				align-items center
				padding 0 6px
				margin 6px 8px
				line-height 36px
				min-height 36px
				white-space nowrap
				min-width 88px
				text-align center
				text-transform uppercase
				font-size 14px
				text-decoration none
				cursor pointer
				border 1px solid transparent
				border-radius 2px
				&:hover, &:active
					background-color rgba(0, 0, 0, 0.05)
				&:focus
					border 1px solid rgba(0, 0, 0, 0.1)
			button.btn
				margin 6px 4px
.alertify-logs
	position fixed
	z-index 100
	&.bottom, &:not(.top)
		bottom 16px
		// Bottom left placement. Default. Use for transitions.
		//&.left, &:not(.right)
		//	> *
		// Top right placement
		//&.right
		//	> *
	// All left positions.
	&.left, &:not(.right)
		left 16px
		.alertify-log
			float left
			// transform translateZ(0)
			// height auto
			// &.show
			// 	left 0
			// &, &.hide
			// 	left -110%
	&.right
		right 16px
		.alertify-log
			float right
			transform translateZ(0)

			alertify-log-right-show()
				right 0
			alertify-log-right-hide()
				right -110%

			alertify-log-right-show()

			&.alertify-log-enter
				alertify-log-right-hide()

			&.alertify-log-enter-active
				alertify-log-right-show()

			&.alertify-log-leave
				alertify-log-right-hide()

			&.alertify-log-enter-active
				alertify-log-right-show()
	&.top
		top 0
		// Top left placement, use for transitions.
		//&.left, &:not(.right)
		//	> *
		// Top right placement, use for transitions
		//&.right
		//	> *
	.alertify-log
		padding padding-base
		color #fff
		box-shadow box-shadow-size
		border-radius border-radius
		&, &.default
			background rgba(0, 0, 0, 0.8)
		&.error
			background danger-color
		&.success
			background success-color
		box-sizing border-box
		transition all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)
		position relative
		clear both
		backface-visibility hidden
		perspective 1000

		alertify-log-show()
			margin-top padding-base
			opacity 1
			max-height 1000px
			padding padding-base
			pointer-events auto

		alertify-log-hide()
			max-height 0
			margin 0
			padding 0
			overflow hidden
			opacity 0
			pointer-events none
		
		alertify-log-show()

		&.alertify-log-enter
			alertify-log-hide()

		&.alertify-log-enter-active
			alertify-log-show()

		&.alertify-log-leave
			alertify-log-show()

		&.alertify-log-leave-active
			alertify-log-hide()

.notification-banners
	position absolute
	width 100%
	// top: nav-height

.notification-banner
	background-color ncss-error
	color ncss-white

.notification
	width 100%
	display flex

	span
		flex 1 0 auto
		// display inline-block
	button
		flex 0 0 auto
		background-color: transparent
		color ncss-white
