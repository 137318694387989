 .modal-container
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    background-color: #fff;
    margin: 0 auto;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    width: 75%;
    border: 3px solid #2c3e50 !important;
    @media screen and (max-width: 1024px)
      top: 54%
      height: 93%
      width: 100%;
    @media screen and (max-width: 768px)
      top: 56%
      height: 88%
      width: 100%;

  .modal-overlay
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background-color rgba(0,0,0,0.5)
    z-index 12

  .modal-close-btn
    background transparent
    float right
    font-size: 20px

.modal-right-container
    position fixed
    top 44px
    right 0
    background-color #ffffff
    margin 0
    padding 10px 20px
    width 50%
    height 100vh
    @media screen and (max-width: 640px)
      width 90%
      top 40px
    @media screen and (max-width: 1200px) and (min-width: 640px)
      width 60%
      top 40px

.decorationList
  display flex
  flex-wrap wrap

.scrollable-modal
  overflow-y: auto     

.padding-popup
  margin-left: 25px;

.grey-button-icon-popup
    background-color: rgba(0,0,0,0.8);
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    padding: 1px 5px;
    /* border-radius: 15%; */
    margin-bottom: 5px;