.carousel.carousel-slider .control-arrow
    top: 40% !important
    height 15%
    color: #ffffff
    font-size: 0rem !important
    border-radius 50%
    padding: 0 6% !important
    background rgba(0,0,0,0.2) !important 

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before
    margin 0 !important

.carousel.carousel-slider .control-arrow:hover
    border 2px solid  rgba(0,0,0,0.4)
    background rgba(0,0,0,0.3) !important 