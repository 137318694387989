.ag-theme-balham .ag-header {
  border-bottom: 0;
}
.ag-theme-balham .ag-header .ag-header-row:nth-child(2) {
  background-color: #ddd;
}
.ag-theme-balham .ag-header .ag-header-row {
  color: #111;
  font-weight: bold;
  font-size: 14px;
}
.ag-theme-balham .ag-header .ag-header-cell::after,
.ag-theme-balham .ag-header .ag-header-group-cell::after {
  border-right: none;
}
.ag-theme-balham .ag-header .ag-header-cell-label {
  justify-content: center;
}
.ag-theme-balham .ag-row {
  border-color: transparent;
}
.ag-theme-balham .ag-row .ag-cell {
  font-weight: bold;
  font-size: 14px;
  color: #707070;
  border-color: transparent;
}
.ag-theme-balham .ag-row-odd {
  background-color: #f5f5f5;
}
.ag-theme-balham .ag-row-selected {
  background: #bfbfbf;
}
.ag-theme-balham .ag-row-hover {
  background-color: #e5e5e5;
}
.ag-theme-balham .ag-paging-panel {
  justify-content: space-between;
  margin-right: 16px;
  padding: 0px;
  border: none;
  color: #000;
}
.ag-theme-balham .ag-root {
  border: none;
  border-top: 1px solid #bdc3c7;
}
.ag-theme-balham .ag-side-bar {
  border: none;
}
.ag-theme-balham .ag-status-bar {
  border: none;
}
.alert-icon {
  color: #fe0000;
}
.ag-theme-balham .ag-header-cell-label {
  width: 100%;
}
.ag-theme-balham .ag-row {
  border-color: #d9dcde;
}
.span-width {
  margin-left: 22px;
}
.custom-height {
  overflow: hidden;
  height: 29rem;
}
@media screen and (max-height: 714px) and (min-height: 700px) {
  .custom-height {
    height: 15rem;
  }
}
@media screen and (max-height: 750px) and (min-height: 715px) {
  .custom-height {
    height: 16rem;
  }
}
@media screen and (max-height: 799px) and (min-height: 751px) {
  .custom-height {
    height: 19rem;
  }
}
@media screen and (max-height: 850px) and (min-height: 800px) {
  .custom-height {
    height: 22rem;
  }
}
@media screen and (max-height: 900px) and (min-height: 851px) {
  .custom-height {
    height: 26rem;
  }
}
@media screen and (max-height: 1000px) and (min-height: 901px) {
  .custom-height {
    height: 30rem;
  }
}
@media screen and (max-height: 1200px) and (min-height: 1001px) {
  .custom-height {
    height: 55rem;
  }
}
@media screen and (min-height: 1201px) {
  .custom-height {
    height: 55rem;
  }
}
.test-detail-height {
  height: 15rem;
}
.ag-row-no-animation .ag-row {
  transition: none;
}
.ag-header-cell-label {
  text-overflow: clip;
  overflow: visible;
  white-space: normal;
}
.ag-row .ag-cell {
  justify-content: center; /* align horizontal */
  align-items: center;
  text-align: center;
}
.admin-grid-view .ag-layout-normal {
  height: calc(100vh - 42vh);
}
.ag-root-wrapper.ag-layout-normal {
  height: 100%;
}
.ag-theme-balham .ag-header-cell-label .ag-header-cell-text {
  white-space: normal;
}
.custom-height-lg {
  height: 31rem;
}
.custom-height-material {
  overflow: hidden;
  height: 100%;
  display: grid;
}
.upload-height {
  height: 15rem;
}
.cell-wrap {
  white-space: normal !important;
}
.grid-height-adjust {
  height: 100%;
}
.ag-root-wrapper-body.ag-layout-normal {
  flex: 0 0 !important;
  height: auto;
  min-height: auto;
}
