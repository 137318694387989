.filter_dropdown
    position: absolute;
    background: #fff;
    z-index: 999;
    top: 34px;
    border: 1px solid rgba(0,0,0,0.5);
    left: 0px;
    padding: 8px 8px;
    top: 41px;
    height: 500px;
    width: 250px;
    left: 0px;
    background: #e5e5e5;

.filter_dropdownsearch
    position: absolute;
    background: #fff;
    z-index: 999;
    top: 34px;
    border: 1px solid rgba(0,0,0,0.5);
    left: 0px;
    padding: 8px 8px;
    top: -40px;
    height: 500px;
    width: 250px;
    left: 0px;
    background: #e5e5e5;

   li,.subHeadings
      padding: 5px 0px;
      font-size: 14px;
      color: black;
      font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
      text-transform: capitalize;
   .subHeadings
      color:white;
   .accordion
      border:none
   
   .accordion__button,.accordion__button:hover
      background: rgba(0,0,0,0.8);
      padding: 7px;

   .accordion__button:before 
      border: 6px solid transparent;
      border-left-color: white;
      transform: rotate(-0deg);

   .accordion__button[aria-expanded='true']::before, .accordion__button[aria-selected='true']::before
      transform: rotate(90deg);
      top: 4px;
      position: relative;
     
.search-input input
   width: 100%;
   padding: 6px;
   border: 1px solid #E5E5E5;
   border-radius: 5px;

.filter_icon:before
   color: white;

.filter-dashboard-position
   position: absolute;
   left: 73%;
   top: 1px;
   padding: 23px;
   z-index: 11;

.filter-search-position
   position: absolute;
   right: 28px;
   top:33px;
   height: 48px !important  

.airpage-filtepos
   position: absolute;
   left: 98%;
   top: 2px;
   padding: 23px;
   z-index: 11;

.height-filter-scroll
   max-height: 350px !important
   overflow-y: auto
   overflow-x: hidden

.height-filter-scroll-res
   overflow-y: auto
   overflow-x: hidden
   width: 100%
   margin-top: 10px

.filter-position
   float: right;
   top: 110px;
   left: 200px;
   position: fixed;
   z-index 11

.filter-dashboard
   background: #333 !important;
    /* padding-top: 48px; */
    padding-bottom: -35px;
    height: 45px;
    width: 90px;
    font-weight: bold;
    border-radius: 0px 30px 30px 0px;
    border: 2px solid #333 !important;

.filter-push-right
   right: 10px

.filter
    background: #333 !important;
    height: 39px;
    width: 90px;
    font-weight: bold;
    border-radius: 0px 30px 30px 0px;
   
   i
      cursor: Pointer;

   .clr_all_btn
      background: none;
      font-weight:600;

   .filter_icon_dashboard
      position: absolute;
      float: right;
      left: 9px;
      top: 14px;
      color: #fff;
      cursor: pointer;

   .filter_icon
      position: absolute;
      float: right;
      left: 9px;
      top: 14px;
      color: #fff;
      cursor: pointer;

   .clear_filter
      border: 1px solid #808080;
      color: #fff;
      position: absolute;
      right: 8px;
      bottom: 3px;
      border: 1px solid #808080;
      color: #fff;
      background: #000000;
      
   .apply_filter
      border: 1px solid #808080;
      color: #fff;
      position: absolute;
      border: 1px solid #808080;
      color: #fff;
      background: #000000;

.filter-name-dashboard
    position: absolute;
    left: 28px;
    top: 6px;
    /* letter-spacing: 0.5px; */
    font-size: 18px;
    color: #fff;

   .filter_name
      
    position: absolute;
    left: 28px;
    top: 5px;
    /* letter-spacing: 0.5px; */
    font-size: 18px;
    color: #fff;

.accordion__panel
   padding:0
   position:relative 

.push_right
   float:right;

.push_left
   float:left;

.text_upper
   text-transform: uppercase;

.price_range
    position: absolute;
    top: 45px;
    left: 0px;
    text-align: center;
    word-spacing: 0.2em;
    width: 100%;

.range_label
    position: absolute;
    top: 35px;
    left: 0px;
    text-align: center;
    word-spacing: 0.2em;
    width: 100%;

.range_label_airsole
    position: absolute;
    top: 35px;
    left: 0px;
    text-align: center;
    word-spacing: 0.2em;
    width: 100%;    

.range_label_top_fix
    position: absolute;
    top: 120px;
    left: 0px;
    text-align: center;
    word-spacing: 0.2em;
    width: 100%;

.no_result
    position: absolute;
    top: 48%;
    left: 47%;
    font-weight: 600;
    font-size: 17px;

.blc_fab
   background-color: #000 !important
   position: absolute !important
   width: 100px !important
   height: 100px !important
   right: 30px
   bottom: 100px 

.blc_fab svg
   color:white
   font-size:60px

.filter-modal
   overflow-y:hidden
   background:#e8e8e8
   width: 100%;
   max-height: -webkit-fill-available 
   overflow: scroll

.modal-text
   color: black;
   font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
   text-transform: capitalize; 

