.container-left
    width: 40%

.container-right
    width: 56% 
    margin-left: 20px
.cardContainer-outer
    display: flex
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: space-around ;


