@require 'variables'

.ncss-form-group .ncss-checkbox-container
  .ncss-label
    font-weight normal

.ncss-form-group
  margin-top 1rem
  .ncss-label
    font-weight bold
