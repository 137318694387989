@require 'variables'
​
.splash-screen
    width: 100%;
    margin-top: 1rem;
​
.sheet-category
    font-size: 1.8rem;
    font-weight: 600;
    text-align: left;
    color: #fff;
    padding: 0px 20px;
    background-repeat: no-repeat;
    background-size: 22% 150px;
    background-position: right;
    overflow: visible;
    z-index: inherit;
    padding: 10px;
    text-align: -webkit-center;
    text-align: center;
    word-break: break-word;
    padding-top: 10px;
​
​
.sheet-bagtype
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    color: rgba(0,0,0,0.7);
   
​
.sheet_border
   background-color: #fff;
   background: rgb(247, 247, 247);
   border: 1px solid rgba(0,0,0,.7);
   
.sheet-card
    position: relative;
    width: 105%;
    height:160px
    font-weight: bold;
    color: #000;
    overflow: hidden;
    text-align: center;
    color: #162A44;
    background: #ffffff;
    padding: 0px;
    margin: 0px
​
.sheet-card-faq
    position: relative;
    width: 90%;
    height: 210px;
    font-weight: bold;
    color: #000;
    overflow: hidden;
    text-align: center;
    color: #162a44;
    margin: 0px;
    box-shadow: 1px 5px 5px 5px #b1acac;
    padding-bottom: 60px;
​
.imp-ml-8
    margin-left: 8px !important

​
.sheet-img
    width: 110px !important;
    height: auto !important;
    margin-left: 4%;
    margin-top: 1px;
    overflow: hidden;
​
.download-position
    position: fixed;
    top: 90px;
    right: 90px; 
​
.sheet_category_container
    margin: 0 10px;
    background: rgba(234, 114, 63, .1);
    //float right
​
.sheet-code
    color: #fff;
    background-color:rgba(234, 114, 63, 1);
    font-size: 12px;
   
​
.pdf-header
    width: 100%;
    padding: 5px;
    margin: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 40px;
    box-shadow: 8px 10px 5px #b1acac;
​
.sheet-price-code
    font-size:12px
​
.icon-solo span
   color: #3498db;
   font-weight: bold;
​
.hide-display
    display: none
​
.rotate-90  
    -webkit-transform: rotateZ(270deg); 
    transform: rotateZ(270deg);
​
.vertical-top
    vertical-align: top
​
​
.bagtype-heading
    padding:40px 0 60px 0
    

.remove-padding
    padding: 0
​
.remove-margin
    margin 0
.top-border
    border-top: 1px solid rgba(0,0,0,.6)
​
.category-border    
     border-top: 1px solid rgba(0,0,0,.6);
​
.blueClass{
    .sheet-category{
        color: #ffffff !important;
        background-color:rgba(66, 142, 255,1) !important;
    }
    .sheet-code{
        background-color:rgba(66, 142, 255,1) !important;
    }
    .sheet_category_container{
        background: rgba(66, 142, 255, .1);
    }
}  
​
.greenClass{
    .sheet-category{
        color: #ffffff !important;
        background-color:rgba(60, 160, 35,1) !important;
    }
    .sheet-code{
        background-color:rgba(60, 160, 35,1) !important;
    }
    .sheet_category_container{
        background: rgba(60, 160, 35, .1) !important;
    }
}

.orangeClass{
    .sheet-category{
        color: #ffffff !important;
        background-color:#fa5400!important;
    }
    .sheet-code{
        background-color:#fa5400 !important;
    }
    .sheet_category_container{
        background: rgba(60, 160, 35, .1) !important;
    }
}
​
.sheet-category-backgorund
    background-size: 100% 100px;
    height: 100px;
    width: 100%;
    background-repeat: no-repeat;
​
.date-style
    float: right
    font-weight: 600
​
.footwear-style
    float: left;
    font-weight: 600;
    margin-left:10px;
    font-size: 25px;
    margin-bottom: -18px;

.faq-height
	height: 200px;

.pdf-document
    width: 100%
.k-pdf-export .ncss-container.fixed-fluid
    min-width: 100%

.k-pdf-export #divToPrint div
    font-family: "DejaVu Sans", "Arial", sans-serif;

// .k-pdf-export #divToPrint .sheet-code
//    font-size: 9px;

// .k-pdf-export #divToPrint .sheet-price-code
//     font-size: 9px;

.k-pdf-export #divToPrint .sheet-card
    height : 160px
.k-pdf-export #divToPrint  .sheet-card .sheet-img
    width 80px
    
.k-pdf-export #divToPrint .sheet-category
.k-pdf-export #divToPrint .sheet-bagtype
.k-pdf-export #divToPrint .footwear-style
    font-weight: bold;

.k-pdf-export .align-right-pdf
    float: right

.k-pdf-export .pdf-header
    padding 2px