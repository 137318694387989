.bag-container
 margin-top 86px

.bag-header
    display: inline-block;
    // max-height: 500px;
    box-sizing: border-box;
    float: right;
    padding-right: 10rem;
    width: 100%;
    border: 1px solid rgba(225,225,225,.3);
    padding: 30px 20px 20px;
 

.bag-title
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    background: rgba(0,0,0,.8);
    color: #fff;
    padding-left: 5px;
    padding-top: 5px;
    margin-bottom: 20px

.bag-ul
    background: #ddd;
    font-size 14px
    margin-bottom 10px
    padding: 10px;
    font-weight: bold

.bag-sizing
  vertical-align top
  padding-right 16px
 
.bag-div
  float right
  margin-top 87px
  background #ffffff
  box-shadow: 5px 5px 5px #b1acac;

.bag-cart
  display flex
  background #fb641b
  box-shadow 0 1px 2px 0 

.bag-top
  text-align center
  font-weight bold
  background: rgba(0,0,0,.8);
  color:#fff
  padding-bottom: 5px
.align-code
 float  right 
.img-top
    text-align: center;
    color: #000;
    font-weight: bold;
    background: #ddd;
    padding: 2px;
    font-size: 20px;

.bag-ul li span
 color black
 float right

.shadow-class
    box-shadow: 8px 10px 5px #b1acac;
    text-transform: uppercase;

.topbar-height
  height: 57px !important
  top: 85px !important
.arrow-bag
    margin-top: 15px;
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 10px

.arrow-span
    padding-left: 6px;
    font-size: 14px;
    color: #000;
    padding-top: 0px;
    margin-bottom: 5px;
    font-weight: bold;
    text-decoration: underline;   
.arrow-div
    position: fixed;
    background: #ddd;
    z-index: 99;
    top: 92px;
    width: 100%;
    left: 0;
    padding: 0 16px;
    border-bottom: 2px solid rgba(0,0,0,.4);

.fixed-search 
    position: fixed;
    top: 89px;
    background-color: #ddd;
    height: 83px;
    padding-top: 16px;
    padding-right: 22%;
    padding-bottom: 10px;
    z-index: 11;
    border-bottom: 2px solid rgba(0,0,0,0.4);

.push-right-search
  right: 3px

.push-right-search-airbag  
  right: 0px
  
.list-top-adjust
  margin-top: 84px  

.arrow-back
 position: fixed;
 top: 89px;
 left: 0;
 z-index: 12;
 background: #ddd;
 padding-top: 14px;
 padding-bottom: 10px;
 border-bottom: 2px solid rgba(0,0,0,.4);
 height: 83px;

.back-search
 padding-right: 9%

.tooltip
 min-width: 50px!important;
 max-width: 200px!important;
 height: 34px !important;
 margin-top: 1px !important; 

.thumbdiv
 margin-top: 20px
 border: 1px solid rgba(225,225,225,.3);
 box-shadow: 8px 10px 5px #b1acac;
 padding:0
.modal-prod
  border: 1px solid rgba(225,225,225,0.3);
  padding: 30px 20px 20px;
  max-height:86px;
  max-width: 100px; 
.modal-dis
  display: flex;
  margin-left: 50px;
  align-items: center;
  padding: 47px 0;
.prod-images
  max-width: 368px;
  position: absolute;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);
.prod-heading
 font-size:24px
.prod-result
   margin-top: 19px;
   margin-left: 37%;
   text-align: center;
   font-size: 17px;
   font-weight: 600;
   @media screen and (max-width: 767px)
    margin-left: 20%
.attach-name
    position: relative;
    right: 59px;
    word-break: break-word;
  .attach-icon
    padding-left: 17px;
@media screen and (max-width: 2040px) 
  .bag-header 
    padding:15px 80px 5px  80px
  
@media screen and (min-width: 2040px) 
  .bag-header 
    padding:15px 150px 5px  150px
    max-height: 100%

@media (max-width: 767px)
  .push-right-search
    right: 44px   

@media (max-width: 1024px)
  .image-container
    width: 100% !important
  .m-10
    margin-left: 0px
  .bag-div  
    margin-top 16px
    box-shadow 5px 5px 5px #b1acac;
    float left
    width 100%
  .bag-sizing  
    vertical-align top
    padding-right 16px
    width 100%
  .pt-0
    padding-top 0px  
  .pl-0
    padding-left 0px 
  .pt-10
    padding-top 10px  
  .pr-0
    padding-right 0px   


   


