.cart-confirmation-popup
    position: relative;
    animation-name: addToCart;
    animation-duration: 1s;
    animation-delay: 0s;

@keyframes addToCart {
  0%{ opacity: 0; margin-top: -20%;}
  50%{ margin-top: 1%; }    
  75%{ margin-top: 2%; }
  100%{ opacity: 1; }
}   

.show-cart-popup .blur-boxs
    opacity: 1;
    visibility: visible;
    background: rgba(0,0,0,.4);

.blur-box
    width 100%
    height 100%
    position fixed
    z-index 1001
    opacity: 1;
    visibility: visible;
    background: rgba(0,0,0,.4);
