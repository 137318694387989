@require 'footer'
@require 'core'
@require 'navbar'
@require 'select'
@require 'ncss'
@require 'forms'
@require 'spinner'
@require 'variables'
@require 'markdown'
@require 'menu'
@require 'alertify'
@require 'tooltip'
@require 'header'
@require 'donut'
@require 'grid'
@require 'dashboard'
@require 'modal'
@require 'list'
@require 'customTable'
@require 'datePicker'
@require 'card'
@require 'layout'
@require 'notAuthorized'
@require 'addProduct'
@require 'detailsCard'
@require 'adminPanel'
@require 'filter'
@require 'chip'
@require 'addItems'
@require 'pagination'
@require 'confirmationModal'
@require 'cheatsheet'
@require 'faq'  
@require 'cart' 
@require 'sizeQuantityTable' 
@require 'cartConfirmationPopup'
@require 'imageEditor'
@require 'searchBar'
@require 'carousel '
@require 'popover'
@require 'progressBar'
@require 'dragAndDrop'
@require 'liveSearch'


html, body, #app-host
  min-height 100%
  background-color #DCDCDCDCDC
  font-family 'Open Sans Condensed', sans-serif

.footer
  position:fixed
  z-index 5
  height 5rem
  bottom 0
  width 100%
  @media screen and (max-width: 640px)
      position relative;



.hide-scroll
  overflow hidden

.offline-section
  padding card-padding
  h2
    text-align center
    font-size 4rem
    text-transform uppercase

.text-color-accent 
    color #0078B9

.u-italic, em
    font-style normal
    color #0078b9

.button-top-adjust .u-uppercase
    float right

.main-heading
    font-size 2.8rem
    height 50px
    width 100%
    overflow hidden
    text-overflow ellipsis
	
*:focus
  outline none

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
  .footer
    position: sticky
    bottom: 0
    width: 100%
    

.not-required-select
    top 15px
    right 285px !important
    width 100px
    position fixed
    z-index  12

.app-container
  margin-top: 80px
  margin-bottom: 100px

.user-center
  text-align -webkit-center

.page-align-items  
  margin: 30px

.color-error
  color: #000000

.user, .usernotfound
  background:#fff
  padding-bottom: 50px;
  padding-top: 50px;
  box-shadow: inset 0 1px 0 0 #e5e5e5, inset -1px 0 0 0 #e5e5e5, inset 0 -1px 0 0 #e5e5e5, inset 1px 0 0 0 #e5e5e5;
  border: 3px solid #ddd;

.user-404
  background:#fff  
  box-shadow: inset 0 1px 0 0 #e5e5e5, inset -1px 0 0 0 #e5e5e5, inset 0 -1px 0 0 #e5e5e5, inset 1px 0 0 0 #e5e5e5;
  border: 3px solid #ddd;

.inline-flex-display
  display flex

.inline-flex-align  
  display inline-flex
  
.admin-grid
    height: 100%

.select__menu
  position: relative !important
  z-index: 150

.sub-heading  
  font-size: 14px

.top-vertical
  vertical-align: top 

.back_color
  background: #ffffff

.airbag-type
  text-transform: uppercase;

.ag-theme-balham .ag-row .ag-cell
  text-transform: uppercase;

.ncss-container.fixed-fluid
  max-width: 87%;

.sub-heading-attachment
    background:hsl(0,0%,90%)
    margin: 5px 2px 5px 2px;
    padding: 4px;
    padding-left: 8px;
    width: 80%;
    border: 1px solid #cfcfcf;
    border-radius: 2px;

.upload-class
  float: right;
  background: none;

.stl_viewer_div
  width 100% !important;

.stl_viewer
  width 100% !important;
  height: 100% !important;

.stl_viewer canvas
  width 100%  !important;
  height: 100% !important;

.m-50
  margin 50px;
  width 60%

.mt-10 
  margin-top 10px;

.ml-32
  margin-left 32px;

.icon-warn i
  font-size 60px

.error-head
  color #e34843
  font-size 48px
  font-weight 900
  margin 20px 0px

.error-title
  color #5e5e5e
  font-size 32px
  font-weight 600
  margin 30px 0px

.error-desc
  word-wrap break-word
  color #777
  font-size 24px
  line-height 1.5