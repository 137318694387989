@require 'variables'

nav-button-padding = 56px


.menu-container
  display: flex;
  width: 100%;
  height: 100%;

.menu-lightbox
  opacity: 0;
  visibility: hidden;
  background: rgba(0,0,0,0);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1012;
  transition: opacity 300ms ease;

.show-menu .menu-lightbox
  opacity: 1;
  visibility: visible;
  background: rgba(0,0,0,0.4);

.menu-logo
  font-size: 22px
  vertical-align: baseline

.menu-aside 
  display: flex;
  flex-direction: column
  position: fixed;
  left: 0;
  width: 300px;
  height: 100%;
  z-index: 10001;
  overflow: hidden;
  transform: translateX(-100%);
  transition: transform 300ms ease;
  background: #ffffff
  
  .nav
    width: 100%;
    flex 0 0 auto
    overflow-y: auto;
    background: #ffffff

  .footer
    flex 1 0 auto
    display flex
    flex-direction: column
    align-items: center

    .footer-spacer
      display block
      flex 1 1 0
    
    span
      margin 0 auto
      flex 0 0 auto
      display flex

.show-menu .menu-aside 
  transform: translateX(0);
  border-right: 2px solid #ddd;
  box-shadow: inset 0 1px 0 0 #e5e5e5, inset -1px 0 0 0 #e5e5e5, inset 0 -1px 0 0 #e5e5e5, inset 1px 0 0 0 #e5e5e5;

.viewport
  display: flex;
  flex-direction:  column
  width: 100%;
  height: 100%;
  overflow: auto;

.menu-btn
  position: fixed;
  top: 8px;
  left: 8px;

.profile-nav
  position relative
  display flex
  flex-direction row
  align-items center

  .profile-nav-item
    flex 0 0 auto
    vertical-align middle
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
    min-width 0
    span
      white-space nowrap
      text-overflow ellipsis
      overflow hidden
      min-width 0

  &.profile-nav-item
    flex 1 1 auto
    
  .user-info
    flex 1 1 auto


.badge-notification
  position absolute
  right -8px
  top -8px
  font-size 1rem
  background-color red
  color ncss-white
  display inline
  line-height .8rem
  padding 3px 5px

.menu-header
  text-align left
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: #000000 !important

.seperation-line
  color ncss-grey-light
  margin 15px 0px 30px 0px
  border 0
  border-bottom 1px solid #cecece;
  @media screen and (max-width 728px)
      margin 10px 0px 10px 0px

.seperation-header
  background-color: #efefef
  margin 10px -24px 5px -24px
  padding 10px 24px

.bg-color
   background: #f7f7f7

.li-bg-color
  padding: 10px;
  font-family: "Open Sans Condensed", sans-serif;

.padding-li-bottom
   padding-bottom: 10px 
  
ul
  padding: 20px
  padding-bottom: 30px

.li-bg-color:hover
   background: rgba(0,0,0,.1);
   padding:10px;
   box-shadow: 8px 10px 5px #b1acac;

.li-padding-right
  padding-right: 28px;


.padding-li-bottom-head
   padding-bottom: 20px 