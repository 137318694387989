 .button-agrid
        color:white
        font-size: inherit 
        text-transform: uppercase
        font-family: "Nike TG","Helvetica Neue",Helvetica,Arial,sans-serif
        font-weight: 400
        letter-spacing: .5px
        border-radius: 15px
        white-space: nowrap

.size-bubble-background
    background: rgba(0,0,0,.8);
    padding: 2px;
    margin: 2px;
    color: #ffffff
    padding-left: 5px;
    padding-right: 5px;
    font-size: 11px;
    text-align: center;

.icon-size-adjust
        font-size: 1.3rem
        color: rgba(0,0,0,0.8)
        cursor: default
        
.btn-color-blue
        background #0078b9
.dot 
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle

.dot-active
.text-color-green
   color: #008000;

.dot-inactive
   color: red;

.fas.fa-edit:hover 
    color: #0078b9;
    cursor: pointer; 
    font-size:1.5em; 

.bubble-size
    width 38px

.left-text-align
    text-align: left
    
.text-excel-color-grid
    color: #111;
    padding: 3px;
    margin-top:10px;
    background: #ddd;
    text-decoration: none;
    border: 2px solid #cfcfcf;
    font-size: 11px;
.gap
   margin-left: 10px;
  


