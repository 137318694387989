
.editor-complete-area
    height 500px

.image-editor
    border: 2px solid rgba(0,0,0,.8);

.editor-area
    background: white;
    color:black
    width: 300px !important;
    height: 300px !important;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid rgba(0,0,0,0.8);

.upload-image-button
    display block !important
    color :black
    margin-top:20px;
    background: #eaeaea

.editor-upload-button
    width:96px
    overflow hidden

.editor-upload-area
    position relative
    margin-top:12px
    margin-bottom: 12px

.editor-upload-file-name
    position absolute
    top 0
    left 100px
    font-weight: 600

.editor-slider
    width: 100%;
    height: 10px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border: 1px solid rgba(0,0,0,0.8);

.editor-divider
    color: white;

.editor-slider:hover {
  opacity: 1;
}

.editor-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%
  background: #d3d3d3;
  border 1px solid black
  cursor: pointer;
}

.editor-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%
  background: #d3d3d3;
  border 1px solid black
  cursor: pointer;
}

.option-head
    color black
    font-size: 14px
    font-weight: 600

.editor-options
    padding: 3px
    vertical-align: text-top

.editor-left-icon
    border-radius: 40%
    color white
    background: rgba(0,0,0,.8);

.rotate-left-button
    width 100%
    border: 1px solid rgba(0,0,0,0.8);

.rotate-right-button
    width 100%
    border: 1px solid rgba(0,0,0,0.8);

.editor-preview
    border: 2px solid rgba(0,0,0,0.8);
    vertical-align: top;
    height: 95.9%;
    background: #ddd;

.editor-preview-heading
    color: #fff;
    background: rgba(0,0,0,0.8);
    margin: 0 0 6% 0;
    padding: 0;
    font-weight: bold;
    font-size: 24px;

.editor-preview-area
    margin: auto
    background-color: red;
    background-blend-mode: multiply;
    border: 2px solid rgba(0,0,0,0.8);

.editor-dropdown-area
    position relative
    margin-top:12px
    margin-bottom: 24px

.editor-dropdown
    width:100px
    
.image-type-label
    position absolute
    top 0
    font-weight: 600

.image-type-dropdown    
    position absolute
    top 0
    left 80px
    font-weight: 600