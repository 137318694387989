.react-calendar
  width 250px !important 
  max-width 100% !important
  z-index 3000
  position relative !important
  background-color white !important
  border 1px solid black 

.react-calendar__navigation__label 
.react-calendar__navigation__arrow 
  font-weight: 600
  font-size: 10px

.react-calendar__navigation
   border-bottom 1px solid #a0a096

.react-calendar__tile
  border-radius 5px

.react-calendar__tile--active  
  background #3498db
  font-weight: 900

.react-date-picker__wrapper 
    display flex
    border thin solid gray


.react-date-picker__inputGroup 
  min-width calc((4px * 3) + 0.54em * 8 + 0.217em * 2)
  flex-grow 1
  display flex
  padding 0 2px
  align-items baseline
  box-sizing content-box

.react-calendar__decade-view
   padding: 0.3rem

.react-calendar__tile
  //padding 5px !important
  font-size: 12px
  flex-basis: 30.3333% !important
  margin 1px !important

.react-date-picker__calendar
  position relative !important
  height: 100% !important


.react-date-picker__calendar--closed 
  display none

.react-date-picker__clear-button
  display none    

.react-date-picker__inputGroup__input 
  min-width 0.54em
  width 100% !important
  height 100%
  position relative
  padding 0 1px
  border 0
  background none
  font inherit
  box-sizing content-box


.DayPicker-Border  input
  border: 1px solid #000000
  padding: 4px 7px;
  border-radius: 0;
  width: auto !important;
  max-width: 150px;

.DayPicker-Day--monday 
  color: #00bcd4

.DayPicker-Day
  padding: 2px 0.5em;

.react-date-picker__calendar-button__icon   
  vertical-align: middle